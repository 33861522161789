.mainContainerStyle {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
}
.headerViewStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: 1px solid var(--border-color);
}
.headerLeftViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.backImgViewStyle {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgStyle {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: cover;
}
.assetSectionViewStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: 1px solid var(--border-color);
}
.assetDetailsViewStyle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2px;
}
.assetIdViewStyle {
  display: flex;
  width: fit-content;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
  background: var(--bg-primary-color);
}
.titleTextStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.subTitleTextStyle {
  color: var(--desc-color80);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.assetIdTextStyle {
  color: var(--desc-color80);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.scannerImgViewStyle {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #e8e8e8;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(196, 196, 196, 0.12);
}
.scannerViewStyle {
  width: 24px;
  height: 24px;
  display: flex;
}
.ticketDetailsViewStyle {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  overflow: scroll;
}
.setpOneSectionViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.lableTextStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
}
.btnViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.customBtnStyle {
  width: fit-content;
}
.imgPreViewModalStyle {
  width: 80%;
  height: 50%;
  display: flex;
}
.imgPreViewStyle {
  width: 100%;
  height: 100%;
  display: flex;
}
.modalCloseButtonStyles {
  position: absolute;
  top: -20px;
  right: -20px;
  background: var(--bg-primary-color);
  border: none;
  cursor: pointer;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100%;
}
.ticketPreviewContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.timelineContainer {
  width: 100%;
}

.timeline {
  height: 100%;
  width: 100%;
  list-style-type: none;
  padding: 0;
}

.timelineItem {
  position: relative;
  width: auto;
  padding-left: 8px;
  margin-top: 8px;
}

.timelineItem:after,
.indicator {
  position: absolute;
  content: '';
}

.timelineItem:after {
  left: 3px;
  top: 0;
  height: 104%;
  width: 0px;
  border: 1px dashed rgb(154, 154, 154);
  border-radius: 20px;
}

.timelineItem:last-child:after {
  height: 0;
}

.indicator {
  left: -11px;
  top: 0;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerDot {
  height: 15px;
  width: 15px;
  background-color: rgb(210, 210, 210);
  border-radius: 50%;
  z-index: 2;
}

.event {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 8px;
}

.iconContainer {
  height: 100px;
  width: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconContainer:before {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  left: -20px;
  top: 5px;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.timelineItem:nth-child(1) .event .iconContainer:before {
  border-right: 10px solid #ff6347;
}

.timelineItem:nth-child(2) .event .iconContainer:before {
  border-right: 10px solid #1e88e5;
}

.timelineItem:nth-child(3) .event .iconContainer:before {
  border-right: 10px solid #33cc33;
}
.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.timelineTitleTextStyle {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.ticketAssetDetailsStyle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: var(--bg-secondary-color);
  border-radius: 8px;
  width: 100%;
}
.assetImageViewStyle {
  width: 200px;
  height: 200px;
}
.assetImageStyle {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.assetDetailStyle {
  display: flex;
  flex-direction: column;
}
.assetDetailTitleStyle {
  overflow: hidden;
  color: var(--text-color);
  text-overflow: ellipsis;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.assetDetailDescriptionStyle {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px; /* 142.857% */
}
.customUseIconStyle {
  margin-left: 0px;
}
.errorTextStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--error-color);
}
.modalStyle {
  padding: 20px;
}
.noteViewStyle {
  resize: none;
}
.modalCustomStyle {
  width: 568px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.successCardBtnStyle {
  width: 100%;
}
@media screen and (max-width: 480px) {
  .modalCustomStyle {
    width: 95%;
  }
}
