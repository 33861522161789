.containerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.labelStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.uploadBoxStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 16px;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 4px;
}
.placeholderStyle {
  color: var(--desc-color40);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.btnStyle {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  gap: 4px;
  padding: 8px;
  white-space: nowrap;
}
.removeFileStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--light-gray-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  gap: 4px;
  padding: 8px;
  height: 38px;
  max-width: 200px;
  width: fit-content;
}
.removeFileImgStyle {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.removeFileImgStyle img {
  object-fit: contain;
}
.removeFileNameStyle {
  color: var(--desc-color40);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.verticalLineStyle {
  border: 1px solid var(--border-color);
  height: 100%;
}
.imgPreviewLinkStyle {
  display: flex;
  gap: 4px;
  cursor: pointer;
}
.imgPreviewIconStyle {
  width: 20px;
  height: 20px;
}
.imgPreviewTextStyle {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
