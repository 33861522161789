.authBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.paginationContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.dotBtnStyle {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background: var(--border-color);
}
.activeDotBtnStyle {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background: var(--primary-color);
}
.loginWrapperStyle {
  width: 386px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background: var(--white);
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
}
.loginWrapperTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}
.inputLabelStyle {
  font-size: 12px;
  line-height: 16px;
}
.otpSectionWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.resendEditEmailTextWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--desc-color60);
}
.resendEditEmailTextWrapperStyle span {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}
.loginBtnWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.errorTextStyle {
  color: var(--error-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.proceedBtnStyles {
  width: 100%;
  background-color: var(--text-color);
  text-align: center;
  padding: 12px 24px;
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
  position: relative;
}
.loaderStyle {
  width: 30px;
  height: 30px;
}
.googleBtnStyles {
  width: 100%;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--white);
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.googleIconStyle {
  width: 20px;
  height: 20px;
}
.signupTextStyle {
  color: var(--desc-color60);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.signupTextStyle span {
  color: var(--primary-color);
  text-decoration: underline;
  margin-left: 4px;
  cursor: pointer;
}
.userProfleStyle {
  display: flex;
  gap: 8px;
  align-items: center;
}
.userIconStyle {
  width: 36px;
  height: 36px;
}
.userInfoStyle {
  display: flex;
  flex-direction: column;
}
.userTitleStyle {
  overflow: hidden;
  color: var(--text-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.userDescStyle {
  color: var(--desc-color80);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.userCompaniesWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: fit-content;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.companyBtnStyle {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  background: var(--light-mint-green-color);
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
  flex-shrink: 0;
}
.companyBtnIconStyle {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 480px) {
  .loginWrapperStyle {
    padding: 16px;
    width: 100%;
  }
}
