.containerStyle {
  width: 568px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(225, 226, 228, 1);
}
.eachWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  border-bottom: 1px solid rgba(225, 226, 228, 1);
  padding: 12px 0px;
}
.infoViewStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  width: 100%;
  padding: 12px 0px;
  border-bottom: 1px solid rgba(225, 226, 228, 1);
}
.lableStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  color: var(--desc-color40);
}
.textStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--text-color);
  word-break: break-all;
}

@media screen and (max-width: 480px) {
  .containerStyle {
    width: 100%;
  }
}
