.authLayoutStyle {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.logoWrapperStyle {
  position: absolute;
  left: 28px;
  top: 20px;
  height: 24px;
  width: fit-content;
}
.authLayoutContentStyle {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.authLayoutRightSectionStyle {
  width: 50%;
  height: 100%;
  display: flex;
  background-image: url('../../assets/images/demo-layout.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 900px) {
  .authLayoutRightSectionStyle {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .authLayoutContentStyle {
    width: 100%;
    height: 100%;
    padding: 16px;
  }
}
