.ticketOverViewCardWrapperStyle {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: var(--bg-secondary-color);
  /* gap: 16px; */
}
.forwardedWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  background-color: var(--bg-primary-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
}
.forwardedTextStyle {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--desc-color60);
}
.dropDownIconStyle {
  width: 10px;
  cursor: pointer;
}
.ticketTimelineModalStyle {
  width: 320px;
  bottom: unset;
  top: 100%;
  left: 16px;
  right: unset;
  padding: 16px;
  padding: 8px;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.timeLineContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.timeLineItemWrapperStyle {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  position: relative;
}
.timeLineItemWrapperStyle:not(:first-child)::before {
  content: '';
  position: absolute;
  left: 9.5px;
  border-left: 1px solid var(--medium-gray-color);
}
.timeLineItemWrapperStyle:not(:last-child)::after {
  content: '';
  position: absolute;
  top: calc(25%);
  left: 9.5px;
  height: 100%;
  border-left: 1px solid var(--medium-gray-color);
}
.timeLineImgContainerStyle {
  display: flex;
  min-width: 20px;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.timeLineImgStyle {
  width: 12px !important;
  height: 12px !important;
}
.timeLineTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.timeLineSubTitleStyle {
  color: var(--desc-color40);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.timelineNoteViewStyle {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--white);
  box-shadow: 0px 4px 16px 0px var(--shadow-color08);
}
.timelineNoteTextStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.primaryBgStyle {
  background-color: var(--primary-color) !important;
}
.redBgStyle {
  background-color: var(--magenta-rose-color) !important;
}
.ticketDetailsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
.ticketWrapperStyle {
  border-radius: 4px;
  background: var(--desc-color05);
  overflow: hidden;
  width: 192px;
}
.ticketNameAnddIdViewStyle {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}
.titleTextStyle {
  color: #1e1e20;
  text-overflow: ellipsis;
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding: 4px 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 1; */
  align-self: stretch;
  overflow: hidden;
}
.ticketImageStyle {
  max-width: 192px;
  width: 100%;
  max-height: 148px;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
}
.issueDetailsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.ationBtnsViewStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0px 16px 16px;
}
.btnStyle {
  width: fit-content;
  padding: 8px 12px;
  height: auto;
  border-radius: 4px;
}
.imageModalContainerStyle {
  max-width: 440px;
  width: 100%;
  max-height: 440px;
  height: 100%;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
.imageModalWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.modalImageStyle {
  max-width: 440px;
  width: 400px;
  max-height: 440px;
  height: 400px;
}
/* // info styles  */
.infoViewStyle {
  width: 100%;
  max-width: 568px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(225, 226, 228, 1);
}
.customLabelStyle {
  font-weight: 700 !important;
}
