.remaindersMainContainerStyle {
  width: 100%;
  background-color: var(--pale-lavender-color);
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
}
.noRemaindersWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.iconContainerStyle {
  min-width: 20px;
  height: 20px;
}
.noRemindersTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.learnMoreTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--primary-color);
}
.remainderItemWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--shadow-color16);
}
.remainderItemWrapperStyle:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: none;
}
.remainderItemLeftWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
  cursor: pointer;
}
.iconCheckContainerStyle {
  min-width: 20px;
  height: 20px;
}
.iconOptionsContainerStyle {
  position: relative;
  min-width: 20px;
  height: 20px;
  cursor: pointer;
}
.remainderTextWrapperStyle {
  display: flex;
  flex-direction: column;
}
.remainderTitleTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.remainderTimeTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: var(--desc-color60);
}
.actionOptionsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 176px;
}
.actionPopUpViewStyle {
  top: -4% !important;
  /* bottom: unset; */
  right: -13px !important;
  padding: 8px;
  background-color: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  border: 1px solid var(--border-color);
  width: fit-content;
}
.optionsViewStyles {
  padding: 4px;
  width: 100%;
  border-radius: 4px;
  background: var(--lightGrayishBlue);
  color: var(--textDark);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  cursor: pointer;
}
.optionsViewStyles:hover {
  background-color: var(--bg-primary-color);
}
.deleteOptionViewStyle {
  color: var(--error-color);
}
.alertModalViewStyle {
  width: 320px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
  z-index: 2;
}
.deleteModalContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
.deleteTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color60);
}
.spanTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.yesBtnStyle {
  background-color: var(--error-color);
}

.reminderModalViewStyle {
  position: fixed;
  top: 60px;
  left: unset;
  right: 20px;
  width: 568px;
  border-radius: 4px;
}
.disableviewStyle {
  pointer-events: none;
  opacity: 0.5;
}
.remainderOverModalOverlayStyle {
  z-index: 1;
}
.remainderOverModalStyle {
  width: 480px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
  z-index: 10;
}

@media screen and (max-width: 480px) {
  .reminderModalViewStyle {
    width: 100%;
    height: auto !important;
    border-radius: 8px 8px 0 0;
    display: flex;
    position: fixed;
    bottom: 0 !important;
    right: auto !important;
    /* top: auto !important; */
  }
  .remainderOverModalStyle {
    position: fixed !important;
    width: 100%;
    top: unset !important;
    left: unset !important;
    bottom: 0px !important;
    height: fit-content;
    transform: none !important;
    border-radius: 8px 8px 0px 0px;
  }
  .remainderOverModalOverlayStyle {
    z-index: 2;
  }
}
