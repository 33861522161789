.containerStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100% - 120px);
  height: 100%;
  overflow: hidden;
}
.headerViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}
.headerTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-color);
}
.closeIconStyle {
  min-height: 20px;
  min-width: 20px;
  cursor: pointer;
}
.remindersWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.tabsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.activeTabStyle {
  background-color: var(--pale-lavender-color);
}
.upcomingRemaindersViewStyle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: calc(100vh - 274px);
  height: 100%;
  overflow: scroll;
  background-color: var(--bg-secondary-color);
  padding: 0px 12px;
  border-radius: 8px;
}
.completedRemindersListStyle{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 274px);
  height: 100%;
  overflow-y: scroll;
}
.completedReminderItemsDateWrapperStyle{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.completedReminderItemsWrapperStyle{
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0 12px;
  background-color: var(--bg-secondary-color);
}
.dateTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--desc-color60);
}
.customReminderStyle {
  background-color: var(--bg-secondary-color);
  border-bottom: 1px solid var(--border-color);
}
.disableviewStyle {
  pointer-events: none;
  opacity: 0.5;
}
.noRemaindersWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
}

.iconContainerStyle {
  min-width: 20px;
  height: 20px;
}
.noRemindersTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.learnMoreTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--primary-color);
}
/* // remainder over view style  */
.remainderOverModalStyle {
  width: 480px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.remainderOverModalOverlayStyle {
  z-index: 1;
}
@media screen and (max-width: 480px) {
  .remainderOverModalStyle {
    display: flex;
    height: auto;
    width: 100%;
    position: fixed;
    top: auto !important;
    right: 0 !important;
    left: unset !important;
    bottom: 0 !important;
    border-radius: 8px 8px 0px 0px;
    transform: none;
    z-index: 2;
  }
}
