.containerViewStyle {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
}
.userIconViewStyle,
.iconViewStyle {
  width: 20px;
  height: 20px;
  display: flex;
}
.userIconViewStyle {
  margin-top: 8px;
}
.iconViewStyle2 {
  cursor: pointer;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.inputBoxViewStyle {
  display: flex;
  padding: 8px;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--white);
  height: 56px;
  width: 100%;
}
.inputViewStyle {
  border: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  resize: none;
  border-radius: 0px;
}
