.containerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.radioStyle {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  cursor: pointer;
}
.selectedStyle {
  background: var(--primary-color);
  border-color: transparent;
}
.iconStyle {
  width: 70%;
  height: auto;
}
.labelStyle {
  color: var(--desc-color40);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
