.containerStyle {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.suggestionBtnWrapperStyle {
  display: flex;
  gap: 8px;
}
.statisticsBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.dashboardSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.staticCardStyles {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.labelTitleStyle {
  font-family: 'InterTight', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--black);
}
