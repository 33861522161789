.dashboardLayoutStyle {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
}
.headerAndContentWrapperStyle {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.contenWrappertStyle {
  width: 100%;
  height: calc(100vh - 60px);
  overflow: scroll;
}
.contentWithNoHeeaderStyle {
  height: calc(100vh - 10px);
}
.remainderOverModalOverlayStyle {
  z-index: 1;
}
.remainderOverModalStyle {
  width: 480px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
  z-index: 10;
}
.notificationsViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  height: 100%;
  overflow: scroll;
}
