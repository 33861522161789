.containerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.headerSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
}
.headerTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-color);
}
.iconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.inputWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
  width: 100%;
  padding: 20px 20px 0px 20px;
}
.inputSubWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 12px 16px;
}
.inputSectionStyle {
  border: none;
  padding: 0px;
  gap: 8px;
}
.inputStyle {
  padding: 0px;
  height: 20px;
  border-radius: 0px;
}
.optionsModalViewStyle {
  top: 15px !important;
  left: -16px !important;
}
.membersOptionsViewStyle:hover {
  background-color: var(--light-mint-green-color);
}
.accessSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.accessLabelTextStyle {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--text-color);
  text-wrap: nowrap;
}
.disableStyle {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--desc-color60);
  text-wrap: nowrap;
}
.downIconStyle {
  width: 10px;
}
.accessModalStyle {
  padding: 4px;
  top: 8px !important;
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
  border: 1px solid var(--border-color);
}
.accessOptionsWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.accessOptionsViewStyle {
  width: 128px;
  display: flex;
  flex-direction: row;
  padding: 4px 0px 4px 8px;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 4px;
}
.accessOptionsViewStyle:hover {
  background-color: var(--bg-primary-color);
}
.activeOptionViewStyle {
  background-color: var(--light-mint-green-color);
}
.accessOptionTextStyle {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.membersSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 20px 20px 20px;
}
.membersViewStyle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 250px;
  overflow: scroll;
}
.membersLabelStyle {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--desc-color60);
}
.eachMemberWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.membersDetailsStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.membersDetailsSubViewStyle {
  display: flex;
  flex-direction: column;
}
.memberTitleTextStyle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}
.memberDescTextStyle {
  font-size: 12px;
  font-weight: 450;
  line-height: 16px;
  color: var(--desc-color60);
}
.accountTypeStyle {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--text-color);
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--lavender-color);
  box-shadow: 0px 4px 16px 0px var(--lavender-mist-color);
}
.adminTypeStyle {
  background: var(--light-mint-green-color);
  box-shadow: 0px 4px 16px 0px var(--pale-mint-green-color);
}
.superAdminTypeStyle {
  background-color: var(--pale-pink-color);
  box-shadow: 0px 4px 16px 0px var(--pale-pink-shade-color);
}
.bottomSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
  padding: 20px 20px 20px 20px;
}
.bottomLetfSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.bottomleftTextStyle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--desc-color60);
}
.bottomRightSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.bottomRightTextStyle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--primary-color);
}
.disableEventStyle {
  opacity: 0.6;
  pointer-events: none;
}

@media screen and (max-width: 480px) {
  .optionsModalViewStyle {
    top: unset !important;
    bottom: 15px !important;
  }
  .customAccessModalStyle {
    height: fit-content;
    top: unset !important;
    bottom: 20px;
  }
}
