.containerStyle {
  display: flex;
  list-style-type: none;
  margin: auto;
  padding: 0px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  z-index: 0;
}
.imgContainerStyle {
  background-color: var(--medium-gray-color);
  border-right: 1px solid var(--bg-secondary-color);
  border-radius: 100%;
  color: var(--desc-color60);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  height: 28px;
  width: 28px;
  margin-left: -5px;
}
.imgContainerStyle:first-child {
  z-index: 2;
}
.imgContainerStyle:nth-child(2) {
  z-index: 1;
}
.imgContainerStyle:last-child {
  z-index: 0;
}
.imgStyle {
  width: 100%;
  height: 100%;
}
