.alertModalContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.modalDataWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.detailsWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.barStyle {
  width: 4px;
  max-height: 100%;
  border-radius: 2px;
  background-color: var(--border-color);
}
.listWrapperStyle {
  display: flex;
  flex-direction: column;
}
.textLabelStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color60);
}
.labelDetailsTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.alertHeaderTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--text-color);
}
.alertBtnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
