.attachmentsContainerStyle {
  width: 568px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.documentItemWrapperStyle {
  max-width: 184px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../../../assets/images/document-bg.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 12px;
  cursor: pointer;
}
.documentTitleTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.documentSizeTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: var(--desc-color40);
}
.emptyViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: var(--bg-primary-color);
  padding: 12px;
  border-radius: 8px;
}

.iconContainerStyle {
  min-width: 20px;
  height: 20px;
}
.emptyTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.learnMoreTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--primary-color);
}
@media screen and (max-width: 480px) {
  .attachmentsContainerStyle {
    width: 100%;
  }
}
