.btnStyle {
  width: fit-content;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  gap: 8px;
  background-color: var(--primary-color);
  color: var(--white);
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 16px var(--btn-primary-shadow-color);
  position: relative;
  overflow: hidden;
}
.primaryBtnStyle {
  background-color: var(--primary-color);
  color: var(--white);
  box-shadow: 0px 4px 16px var(--btn-primary-shadow-color);
}
.whiteBtnStyle {
  background-color: var(--white);
  color: var(--text-color);
  box-shadow: 0px 4px 16px var(--light-gray-shadow-color012);
  border: 1px solid var(--border-color);
}
.darkBtnStyle {
  background-color: var(--text-color);
  color: var(--white);
  box-shadow: 0px 4px 16px var(--light-gray-shadow-color012);
}
.grayBtnStyle {
  background-color: var(--light-gray-color);
  color: var(--text-color);
  box-shadow: 0px 4px 16px var(--light-gray-shadow-color012);
}
.loaderStyle {
  width: 30px;
  height: 30px;
}
.disabledStyle {
  cursor: default;
  opacity: 0.5;
}
.largeBtnStyle {
  padding: 12px 24px;
}
.mediumBtnStyle {
  padding: 8px 12px;
}
.smallBtnStyle {
}
.extraSmallBtnStyle {
}
