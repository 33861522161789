.containerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.headerWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  background-color: var(--bg-secondary-color);
}
.headerSubWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.headerTextStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.headerBtnStyle {
  padding: 4px 8px;
  border-radius: 4px;
}
.noActionVtnStyle {
  cursor: default;
}
.dropDownIconStyle {
  width: 10px;
}
.iconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.activityContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px 20px;
  overflow: scroll;
  margin-bottom: 16px;
}
.activitySubContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tabsWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
}
.statusViewStyle {
  display: flex;
  align-items: center;
  position: relative;
}
.optionsViewStyle {
  width: 100%;
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.ticketStatusOptionsViewStyle {
  width: 320px;
  bottom: unset;
  padding: 8px;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.overViewWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.selectedCapsuleSyle {
  background-color: var(--bg-primary-color);
  border-color: transparent;
}
.flagIconViewStyle {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.flagStyle {
  width: 12px;
  height: 12px;
}
.unresolvedBgViewStyles {
  background: var(--primary-color) !important;
}
.inProgressBgViewStyles {
  background: var(--green-color) !important;
}
.inReviewBgViewStyles {
  background: var(--orange-color) !important;
}
.inMarkAsLostBgViewStyles {
  background: var(--magenta-rose-color) !important;
}
.inClosedBgViewStyles {
  background: var(--medium-gray-color) !important;
}
.assigenedBgViewStyles {
  background: var(--primary-color) !important;
}
.cannotSolveBgViewStyles {
  background: var(--error-color) !important;
}
.activityWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.activityDetailsViewStyle {
  display: flex;
  flex-direction: column;
}
.titleTextStyle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}
.descTextStyle {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--desc-color40);
}
.ticketWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.curvedViewStyle {
  width: 12px;
  height: 12px;
  border-radius: 0px 0px 0px 8px;
  border-bottom: 1px solid var(--medium-gray-color);
  border-left: 1px solid var(--medium-gray-color);
  background: var(--white);
  margin-left: 8px;
}

/* conversations styles */
.commentsBlcokStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.commentsContentSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
}
.titleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.subTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.commentsListStyle {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 24px;
  gap: 16px;
}
.commentsListItemStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.commentsListItemProfileWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
}
.commentAvatarStyle {
  width: 25px;
  height: 25px;
  font-size: 12px;
  font-weight: 600;
}
.commentProfileTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.commentTimeStampStyle {
  color: var(--desc-color40);
  font-size: 12px;
  font-weight: 600;
}
.commentTextStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.replyListWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.replyWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  width: fit-content;
}
.replyLinkStyle {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.rotateIcon180Style {
  transform: rotate(180deg);
}
.replyItemStyle {
  display: flex;
  gap: 8px;
}
.replyCurveStyle {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-style: solid;
  border-color: var(--border-color);
  border-width: 0px 0px 1px 1px;
  border-bottom-left-radius: 8px;
}
.dividerStyle {
  width: 100%;
  border: 1px solid var(--border-color);
}
.moreCommentsLinkStyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  cursor: pointer;
}
/* Timeline */
.timeLineContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.timeLineItemWrapperStyle {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
}
.timeLineItemWrapperStyle:not(:first-child)::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 9.5px;
  height: 50%;
  border-left: 1px solid var(--medium-gray-color);
}
.timeLineItemWrapperStyle:not(:last-child)::after {
  content: '';
  position: absolute;
  top: calc(50% + 10px);
  left: 9.5px;
  height: 50%;
  border-left: 1px solid var(--medium-gray-color);
}
.timeLineImgContainerStyle {
  display: flex;
  min-width: 20px;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background-color: var(--desc-color40);
}
.pinkBgStyle {
  background-color: var(--magenta-rose-color) !important;
}
.grayBgStyle {
  background-color: var(--desc-color40) !important;
}
.primaryBgStyle {
  background-color: var(--primary-color) !important;
}
.greenBgStyle {
  background-color: var(--green-color) !important;
}
.orangeBgStyle {
  background-color: var(--orange-color) !important;
}
.redBgStyle {
  background-color: var(--error-color) !important;
}
.lightGrayBgStyle {
  background-color: var(--medium-gray-color) !important;
}
.timeLineImgStyle {
  width: 12px !important;
  height: 12px !important;
}
.timeLineContentWrapperStyle {
  display: flex;
  flex-direction: column;
}
.timeLineTitleStyle {
  color: var(--text-color);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.timeLineSubTitleStyle {
  color: var(--desc-color40);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* forward modal viewStyle */
.modalViewStyle {
  position: fixed;
  top: 60px;
  left: unset;
  right: 20px;
  width: 568px;
  border-radius: 8px;
}
.takeActionModalViewStyle {
  width: 720px;
  padding: 0;
  left: unset !important;
  right: 20px !important;
  top: 6% !important;
  position: absolute;
}
/* Task tab content */
.taskProgressContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.taskProgressContainerReverseStyle {
  flex-direction: column-reverse !important;
}
.taskChecklistContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
.taskChecklistWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ticketRaiseIconViewStyle {
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 10px;
}
.raiseTicketDetailsBlockStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ticketRaiseByViewStyles {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.ticketRaiseByUserStyles {
  display: flex;
  flex-direction: column;
}
.tickeViewStyles {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.imageStyles {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ticketRaiseDateTextStyles {
  color: var(--desc-color60);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.ticketRaiseTextStyles {
  color: var(--text-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.taskChecklistHeaderWrapperStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.taskChecklistTitleInfoWrapperStyle {
  display: flex;
  gap: 8px;
  align-items: center;
}
.activityTypeImgContainerStyle {
  width: 20px;
  height: 20px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.activityActionImgContainerStyle {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.taskChecklistTitleWrapperStyle {
  display: flex;
  flex-direction: column;
}
.taskChecklistTitleStyle {
  overflow: hidden;
  color: var(--text-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.taskChecklistSubTitleStyle {
  color: var(--desc-color40);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.taskChecklistModalStyle {
  right: 10%;
  /* left: unset !important; */
  padding: 8px;
}
.checkListTitleModalStyle {
  right: 0;
  left: unset !important;
  padding: 16px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.checkListModalBtnWrapperStyle {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}
.clModalDescStyle {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-wrap: wrap;
}
.clModalDesc1Style {
  color: var(--desc-color40);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.checkListModalBtnStyle {
  width: fit-content !important;
}
.dangerBtnStyle {
  width: fit-content !important;
  background-color: var(--error-color);
  color: var(--white);
}
.clItemsImgWrapperStyle {
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
  position: relative;
}
.menuWrapperStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.menuItemStyle {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  width: 100%;
  padding: 4px 8px;
  cursor: pointer;
  text-wrap: nowrap;
}
.menuItemStyle:hover {
  border-radius: 4px;
  background: var(--bg-primary-color);
}
.taskToggleImgContainerStyle {
  width: 20px !important;
  height: 20px !important;
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.taskToggleImgRevContainerStyle {
  transform: rotate(0deg) !important;
  transition: all 0.2s ease-in-out;
}
.taskCLIContentWrapperStyle {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background-color: var(--bg-secondary-color);
  border-radius: 8px;
  width: 100%;
}
.taskItemWrapperStyle {
  display: flex;
  gap: 8px;
  align-items: center;
}
.taskItemTitleWrapperStyle {
  display: flex;
  flex-direction: column;
}
.taskItemTitleStyle {
  color: var(--text-color);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.taskItemTitleStrikeStyle {
  text-decoration: line-through;
}
.taskItemActivityWrapperStyle {
  display: flex;
  gap: 2px;
  align-items: center;
}
.taskItemSubTitleStyle {
  color: var(--desc-color40);
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.taskItemActionContainerStyle {
  width: 16px !important;
  height: 16px !important;
  position: relative;
}
.taskItemActivityModalStyle {
  padding: 16px;
}
.noteBorderWrapperStyle {
  display: flex;
  gap: 8px;
}
.noteBorderStyle {
  width: 4px;
  border-radius: 4px;
  background-color: var(--desc-color20);
}
.signleActivityContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.taskAssetStyles {
  border-radius: 4px;
  background: var(--desc-color05);
  overflow: hidden;
  width: 192px;
}
.taskAssetTitleStyles {
  color: #1e1e20;
  text-overflow: ellipsis;
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding: 4px 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 1; */
  align-self: stretch;
  overflow: hidden;
}
.taskAssetImgWrapperStyles {
  height: auto;
  width: auto;
}
.taskAssetImgStyles {
  height: 100%;
  width: 100%;
}
.pcsContentWrapperStyle {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--bg-secondary-color);
  width: 100%;
}
.pciSubjectStyle {
  color: var(--textDark);
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.pciNoteStyle {
  color: var(--darkBlueOpacity6);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.pcsAssignTitleStyle {
  color: var(--darkBlueOpacity6);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.pcsAssignSubTitleStyle {
  color: var(--textDark);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.customCheckBoxStyle {
  width: 16px;
  height: 16px;
  min-width: 16px;
  border-radius: 4px;
  border: 1px solid rgba(30, 30, 32, 0.2);
  background: #fff;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
.customCheckBoxStyle:checked {
  background: #236bfe;
  position: relative;
}
.disabledCheckBoxStyle {
  opacity: 0.4;
}
.customCheckBoxStyle:checked::before {
  content: url('assets/images/checkIcon.svg');
  position: absolute;
  left: 2px;
  top: -3px;
  width: 16px;
  height: 16px;
}
.emptyViewStyle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emptyViewTextStyle {
  color: var(--desc-color60);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  width: 60%;
}

/* // info styles  */
.infoViewStyle {
  max-width: 568px;
  width: 100%;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(225, 226, 228, 1);
}
.customLabelStyle {
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .modalViewStyle,
  .takeActionModalViewStyle {
    display: flex;
    height: 100%;
    width: 100%;
    position: fixed;
    right: 0 !important;
    border-radius: 8px 8px 0px 0px;
  }
}
