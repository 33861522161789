.dailyBgStyle {
  background-color: var(--orange-color);
}
.weeklyBgStyle {
  background-color: var(--deep-purple-color);
}
.monthlyBgStyle {
  background-color: var(--dark-green-color);
}
.customBgStyle {
  background-color: var(--magenta-color);
}
.dailyTextStyle {
  color: var(--orange-color);
}
.weeklyTextStyle {
  color: var(--deep-purple-color);
}
.monthlyTextStyle {
  color: var(--dark-green-color);
}
.customTextStyle {
  color: var(--magenta-color);
}

.clusterContainerStyle {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.clusterListContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-right: 1px solid var(--border-color);
  max-width: 360px;
  min-width: 360px;
  padding: 20px 8px;
  width: 100%;
  height: 100%;
}
.clusterListHeaderContainerStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
}
.clusterListHeaderContainerEndStyle {
  justify-content: flex-end;
}
.btnSmStyle {
  height: 36px;
  padding: 8px 12px;
}
.clusterCreateModalStyle {
  width: 568px;
  max-height: calc(100% - 2 * var(--header-height));
  position: fixed;
  top: var(--header-height);
  left: 50%;
  transform: translateX(-50%);
}
.searchInputContainerStyle {
  width: 36px;
  height: 36px;
}
.activeSearchInputContainerStyle {
  transform-origin: right;
  transition: width 0.3s ease-in-out;
  width: 100%;
}
.searchInputStyle {
  height: 100%;
  padding: 0px !important;
}
.activeSearchInputStyle {
  padding: 0 12px !important;
}
.searchInputIconStyle {
  right: 18px;
}
.clusterListBodyWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100% - 36px);
}
.clusterListBodyHeaderStyle {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  gap: 8px;
}
.clisterListBodyHeaderTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.clisterListFilterBtnStyle {
  gap: 4px;
  padding: 4px 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.sortClusterModalStyle {
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 8px;
  top: -26px !important;
  display: flex;
  flex-direction: column;
}
.sortClusterListItemWrapperStyle {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
}
.sortClusterItemCheckboxStyle {
  width: 16px;
  height: 16px;
  padding: 1px;
}
.sortClusterItemTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  white-space: nowrap;
}
.clusterListBodyStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.emptyDataBlcokStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.emptyDataTextStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.clusterListItemStyle {
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
}
.selectedClusterListItemStyle,
.clusterListItemStyle:hover {
  background-color: var(--bg-secondary-color);
}
.clusterListItemIconStyle {
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 4px;
  flex-shrink: 0;
}
.clusterListItemRightStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.clusterListItemTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.clusterListChipWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.clusterListItemSubTitleStyle {
  color: var(--desc-color80);
  font-family: 'InterTight', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.clusterListItemSubWrapperBlockStyle {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
}
.clusterListItemDescStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  white-space: nowrap;
}
.barIncompleteStyle {
  background-color: var(--amber-orange-color);
}
.clusterListItemCheckedAssetNoStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;
  line-height: 20px;
}
.clusterListItemCheckedAssetTotalStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.clusterListItemPillStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  background-color: var(--light-pink);
  border-radius: 4px;
  padding: 0 4px;
}
.noClusterSelectedStyle {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noClusterSelectedTitleStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: center;
  width: 60%;
}
.emptyClusterListBlockStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0 20px;
}
.emptyClusterListIconSTyle {
  width: 96px;
  height: 96px;
  border-radius: 16px;
  background-color: var(--primary-color);
  border: 2px solid var(--border-color);
  padding: 8px;
}
.emptyClusterListContentBlockStyle {
  display: flex;
  flex-direction: column;
  max-width: 568px;
  width: 100%;
  gap: 4px;
}
.emptyClusterListTitleStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.emptyClusterListDescStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--desc-color60);
}
.disableActionStyle {
  pointer-events: none;
  opacity: 0.6;
}
@media screen and (max-width: 480px) {
  .clusterListContainerStyle {
    max-width: 100%;
    max-height: 100vh;
    border-right: none;
  }
  .clusterCreateModalStyle {
    width: 100%;
    height: 90vh;
    align-items: flex-end;
    border-radius: 8px 8px 0 0;
    display: flex;
    position: fixed;
    bottom: 0 !important;
    right: auto !important;
    top: auto !important;
  }
}
