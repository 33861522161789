.containerStyle {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  position: relative;
}
.headerViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  background-color: var(--white);
  border-bottom: 1px solid var(--border-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.headerTextStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.crossImgStyle {
  width: 24px;
  height: 24px;
}
.inputsAndBtnSectionStyles {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.inputSectionStyles {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.inputSubWrapperStyles {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.noteViewStyle {
  padding: 8px 16px;
  border-radius: 4px;
  background: var(--bg-secondary-color);
  color: var(--desc-color60);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.inputWrapperStyle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px 16px;
  position: relative;
}
.pointerStyle {
  cursor: pointer;
}
.adminPlaceHolderStyle {
  color: var(--steel-gray-color);
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
}
.adminSelectDropDownIconStyle {
  width: 12px;
  height: 12px;
  transition: 0.5s;
}
.adminSelectDropDownIconStyle2 {
  transform: rotate(180deg);
  transition: 0.5s;
}
.adminsListWithInputStyle {
  width: 100%;
  border-radius: 8px;
}
.adminsListInputStyle {
  border: none;
}
.searchViewStyle {
  width: 100%;
  padding: 4px 10px;
  border-bottom: 1px solid var(--border-color);
}
.avatarStyle {
  background-color: var(--bg-primary-color);
  padding: 8px;
}
.closeIconStyle {
  width: 20px;
  height: 20px;
}
.adminsListWrapperStyle {
  width: 100%;
  max-height: 235px;
  overflow-y: scroll;
}
.adminsListStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  cursor: pointer;
}
.selectedAdminStyle {
  background-color: var(--bg-primary-color);
}
.adminsListStyle:hover {
  background-color: var(--light-mint-green-color);
}
.adminsListImgStyle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: contain;
}
.adminsListTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.adminsListDescStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-weight: 450;
  line-height: 16px;
}
.emptyDataStyle {
  color: var(--desc-color60);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border-top: 1px solid var(--border-color);
}
.listPopUpViewStyle {
  position: absolute;
  width: 360px;
  top: 0% !important;
  right: 0% !important;
  padding: 8px;
  background-color: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  border: 1px solid var(--border-color);
}
.selectedDataViewStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}
.btnViewStyle {
  background-color: var(--bg-primary-color);
  color: var(--text-color);
  padding: 4px 8px;
  border-radius: 4px;
}
.closeIconStyle {
  width: 15px;
  height: 15px;
}
.textAreaStyle {
  resize: none;
}
.btnsSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.btnsWrappeerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 480px) {
  .inputsAndBtnSectionStyles {
    height: 100%;
  }
  .inputSectionStyles {
    height: calc(100% - 50px);
    justify-content: space-between;
  }
  .btnsSectionStyle {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
  .btnsSectionStyle,
  .btnsWrappeerStyle,
  .btnStyle {
    width: 100%;
  }
}
