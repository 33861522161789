.rightTopHeaderContainerStyles {
  display: flex;
  flex-direction: row;
  padding: 16px 20px;
  justify-content: space-between;
  align-items: center;
  background: var(--bg-secondary-color);
}
.rightHeaderLeftBlockStyles {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.rightHeaderLeftSubBlockStyles {
  display: flex;
  flex-direction: column;
  /* gap: 2px; */
}
.assetImgViewStyle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-primary-color);
  border-radius: 100%;
}
.imgViewStyle {
  width: 20px;
  height: 20px;
}
.imgStyle {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: cover;
}
.headerSubTextStyle,
.headerSubTextStyles2 {
  color: var(--desc-color40);
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.headerSubTextStyles2 {
  color: var(--desc-color60);
}
.menuBtnViewStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
}
.backImgViewStyle {
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
  cursor: pointer;
}
.actionPopUpViewStyle {
  padding: 8px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  right: 20px;
  left: unset;
  top: 20px;
  width: 208px;
  border-radius: 8px;
  /* z-index: 10; */
}
.assignPopUpStyles {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
}
.optionTextViewStyle {
  padding: 4px 8px;
  background: var(--white);
  color: var(--text-color);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.optionTextViewStyle:hover {
  background-color: var(--bg-primary-color);
  cursor: pointer;
}
