.wrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.boxStyle {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  /* box-shadow: 0 4px 16px var(--light-gray-shadow-color012); */
  cursor: pointer;
  overflow: hidden;
}
.checkedBoxStyle {
  background-color: var(--primary-color);
}
.iconStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.titleStyle {
  color: var(--desc-color80);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  font-family: 'Manrope', sans-serif;
}
