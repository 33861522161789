.mainContainerStyle {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  gap: 20px;
}
.containerStyle {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 20px 20px;
}
.emailDetailsViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.headerViewStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px;
  gap: 10px;
  border-bottom: 1px solid var(--border-color);
}
.backImgViewStyle {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgStyle {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: cover;
}
.emailDetailsSubViewStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.titleTextStyle {
  color: var(--text-color);
  font-family: 'InterTight';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.descTextStyle {
  color: var(--desc-color80);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.emailDescViewStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--bg-secondary-color);
  width: fit-content;
}
.assetNameTextStyle {
  color: var(--text-color);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.nextBtnViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.lableTextStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
}
.btnViewStyle {
  width: 100%;
}
