.rightSideBarWrapperStyle {
  width: 320px;
  flex: 0 0 320px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 20px;
  border-left: 1px solid var(--border-color);
  background: var(--white);
  overflow-y: scroll;
}
.blockStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.blockHeaderStyle {
  display: flex;
  gap: 8px;
  align-items: center;
}
.blockHeaderTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.blockListItemStyle {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed var(--border-color);
  gap: 8px;
  padding-block: 14px;
}
.blockListItemStyle:hover {
  background-color: var(--bg-primary-color);
  border-radius: 8px;
}
.blockListItemIconStyle {
  min-width: 36px;
  padding: 8px;
  background-color: var(--bg-primary-color);
  border-radius: 8px;
}
.blockListItemAssetIconStyle {
  min-width: 36px;
  padding: 8px;
  background-color: var(--bg-primary-color);
}
.blockListItemUserIconStyle {
  min-width: 36px;
  padding: 8px;
}
.blockListItemTitleStyle {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.blockLinkStyle,
.noDataTextStyle {
  width: fit-content;
  color: var(--desc-color40);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 20px;
  text-decoration: none;
}
.blockLinkStyle:hover {
  color: var(--primary-color);
}
.selectBtnStyle {
  padding: 4px 8px;
  box-shadow: none;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
}
.selectPopUpStyle {
  background: var(--white);
  border: 1px solid var(--bg-secondary-color);
  border-radius: 8px;
  padding: 8px;
  top: -30px !important;
}
.optionStyle {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  max-width: 100%;
  width: 140px;
  cursor: pointer;
}
.optionStyle:hover {
  background-color: var(--light-mint-green-color);
}
.selectedOptionStyle {
  background-color: var(--bg-primary-color);
}
.optionTextStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
