.containerStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.headerViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}
.headerTextStyle {
  color: var(--textDark);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.headerBtnsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.headerBtnStyle {
  padding: 8px 12px;
}
.contineuBtnStyle {
  padding: 8px 12px;
  opacity: 0.4;
}
.activeButtonStyle {
  padding: 8px 12px;
}
.ticketDataViewStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
}
.takeActionWrapperStyle {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}
.takeActionMiddleWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.taskInfoWrapperStyles,
.taskCLWrapperStyle,
.taskAssignWrapperStyle {
  display: flex;
  gap: 16px;
}
.taskLeftWrapperStyle {
  max-width: 224px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}
.taskLeftTitleStyles {
  color: var(--darkBlueOpacity6);
  font-family: 'Inter Tight', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.taskRightWrapperStyles {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.taskAssetStyles {
  border-radius: 4px;
  background: rgba(30, 30, 32, 0.05);
  overflow: hidden;
  width: 192px;
}
.taskAssetTitleStyles {
  color: #1e1e20;
  text-overflow: ellipsis;
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding: 4px 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 1; */
  align-self: stretch;
  overflow: hidden;
}
.taskAssetImgWrapperStyles {
  height: 148px;
  width: auto;
}
.taskAssetImgStyles {
  height: 100%;
  width: 100%;
}
.taskLeftBtnStyle {
  padding: 8px 12px;
}
.textAreaStyle {
  resize: none;
}
.taskLeftWrapperStyle {
  max-width: 224px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}
.taskLeftTitleStyles {
  color: var(--desc-color60);
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.btnViewStyle {
  padding: 8px 12px;
}
.clModalViewStyle {
  position: absolute;
  top: 20px;
  width: 320px;
  height: auto;
  padding: 20px;
  border: 1px solid var(--border-color);
}
.taskCLFormStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.taskCLModalActionStyle {
  display: flex;
  gap: 8px;
  align-self: flex-end;
}
.proceedButtonStyles {
  width: fit-content;
  height: fit-content;
  padding: 8px 12px;
  opacity: 0.4;
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
  color: var(--white);
}
.activeProceedButtonStyles {
  width: fit-content;
  height: fit-content;
  padding: 8px 12px;
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
  color: var(--white);
}
.errorTextStyle {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--error-color);
}
.resetButtonStyles {
  width: fit-content;
  height: fit-content;
  padding: 8px 12px;
  border: 1px solid var(--desc-color20);
  background: var(--white);
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
  color: var(--text-color);
}
.taskCLRightWraperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.taskCLRightWraperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.emptyCheckListStyle {
  padding: 12px;
  border-radius: 4px;
  background: #f3e9ff;
  color: var(--desc-color80);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.emptyCheckListStyle span {
  color: #8323fe;
}
.taskCLContentWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 4px;
  background: #f6f7f9;
}
.taskCLTitleWrapperStyle {
  display: flex;
  justify-content: space-between;
}
.taskCLTitleStyle {
  color: var(--text-color);
  font-family: 'Inter Tight', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.taskCLActionImgWrapperStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
}
.taskCLActionImgStyle {
  width: 100%;
  height: 100%;
}
.customOverlayStyle {
  z-index: 1 !important;
}
.taskCLItemsWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.taskCLItemWrapperStyle {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  position: relative;
}
.taskCLItemUlStyle {
  margin-inline-start: 20px;
}
.taskCLItemTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.taskCLItemActionWrapperStyle {
  display: flex;
  gap: 8px;
}
.itemInputWrapperStyle {
  display: flex;
  gap: 6px;
  align-items: center;
}
.itemInputWrapperBtnsStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.addItemInputStyle {
  height: 36px;
}
.addItemBtnStyle,
.clearBtnStyle {
  width: fit-content;
  height: fit-content;
  padding: 8px 12px;
}
.doneBtnStyle {
  width: fit-content;
  height: fit-content;
  padding: 8px 12px;
  align-self: flex-end;
}
.clearBtnStyle {
  background-color: var(--bg-secondary-color);
  color: var(--textDark);
  border: 1px solid var(--border-color);
}
.menuWrapperStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.menuItemStyle {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  width: 100%;
  padding: 4px 8px;
  cursor: pointer;
  text-wrap: nowrap;
  position: relative;
}
.menuItemStyle:hover {
  border-radius: 4px;
  background: var(--bg-primary-color);
}
.taskChecklistModalStyle {
  right: 0;
  left: unset !important;
  padding: 8px;
}
.checkListTitleModalStyle {
  top: -36px !important;
  right: -8px !important;
  left: unset !important;
  padding: 16px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.checkListDeleteModalStyle {
  top: -70px !important;
  right: -8px !important;
  left: unset !important;
  padding: 16px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.editCheckListItemModalStyle {
  top: 0px !important;
  right: -8px !important;
  left: unset !important;
  padding: 16px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.checkListModalBtnWrapperStyle {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}
.checkListModalBtnStyle {
  width: fit-content !important;
  padding: 8px 12px;
}
.dangerBtnStyle {
  width: fit-content !important;
  background-color: var(--error-color);
  color: var(--white);
  padding: 8px 12px;
}
.clItemsImgWrapperStyle {
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
  position: relative;
}
.clModalDescStyle {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-wrap: wrap;
}
.clModalDesc1Style {
  color: var(--desc-color40);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.labelPopUpStyle {
  position: absolute;
  top: 24px;
  display: inline-flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: #fff;
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
}
.labelWrapperStyle {
  display: flex;
  padding: 4px 23px 4px 8px;
  align-items: center;
  gap: 8px;
}
.labelTextStyle {
  color: var(--textDark);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.customCheckBoxStyle {
  width: 16px;
  height: 16px;
  min-width: 16px;
  border-radius: 4px;
  border: 1px solid var(--desc-color20);
  background: var(--white);
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
.customCheckBoxStyle:checked {
  background: var(--primary-color);
  position: relative;
}
.disabledCheckBoxStyle {
  opacity: 0.4;
}
.customCheckBoxStyle:checked::before {
  content: url('assets/images/checkIcon.svg');
  position: absolute;
  left: 2px;
  top: -3px;
  width: 16px;
  height: 16px;
}
.ticketPrirotyBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ticketProrityTextStyles {
  color: var(--desc-color60);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.yesOrNoButtonTabStyles {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.taskPriorityButtonTabStyles,
.activeTaskPriorityStyles {
  width: 39px;
  height: 36px;
  background: var(--light-gray-color);
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  color: var(--desc-color60);
  border-radius: 4px;
}
.activeTaskPriorityStyles {
  border: none;
  background: var(--bg-primary-color);
  box-shadow: 0px 4px 16px 0px var(----light-gray-shadow-color012);
  color: var(--text-color);
}
.priorityOptionTextStyles {
  color: var(--desc-color40);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.ticketDifficultLevelBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ticketDifficultyTextStyles {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.difficultyLevelBlockStyles {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.taskActionLevelTabStyles {
  background: var(--border-color);
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
}
.activeTaskActionLevelTabStyles {
  border-radius: 4px;
  border: 1px solid var(--green70);
  background: var(--white);
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
  align-items: baseline;
}
.activeTaskPriorityTextStyles {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.defaultDifficultyBgStyle {
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--light-gray-color);
  display: flex;
  padding: 8px;
  align-items: baseline;
  gap: 4px;
  color: var(--desc-color60);
}
.easyBgStyle {
  display: flex;
  padding: 8px;
  align-items: baseline;
  gap: 4px;
  border: none;
  border-radius: 4px;
  background: var(--light-mint-green-color);
  color: rgb(0, 118, 52);
}
.moderateBgStyle {
  display: flex;
  padding: 8px;
  align-items: baseline;
  gap: 4px;
  border: none;
  border-radius: 4px;
  background: var(--dark-gold-color);
  color: var(--);
}
.expertBgStyle {
  display: flex;
  padding: 8px;
  align-items: baseline;
  gap: 4px;
  border: none;
  border-radius: 4px;
  background: var(--pale-pink-shade-color);
  color: var(--magenta-rose-color);
}
.defaultDifficultyTtitleStyle {
  color: var(--desc-color40);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.easyTitleStyle {
  color: var(--dark-green-color);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.moderateTitleStyle {
  color: var(--dark-gold-color);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.expertTitleStyle {
  color: var(--error-color);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.activeDifficultyTextStyles {
  color: var(--green-color);
}
.optionalLabelStyles {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.optionalTextAreaStyles {
  width: auto;
  height: 84px;
  resize: none;
}
.editBtnStyle {
  width: 16px;
  height: 16px;
}
.previewHeaderWrapperStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}
.phLeftWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.phLeftIconWrapperStyle {
  width: 20px;
  height: 20px;
}
.phTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.phPublishBtnStyle {
  width: fit-content;
  padding: 8px 12px;
}
.previewContentWrapperStyle {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}
.pcSubWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.pcsHeaderWrapperStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pchTitleStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.editTaskBtnWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.editTitleStyle {
  color: var(--primary-color);
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.24px;
}
.pcsContentWrapperStyle {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--bg-secondary-color);
  width: 100%;
}
.pciSubjectStyle {
  color: var(--text-color);
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  word-break: break-all;
}
.pciNoteStyle {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  word-break: break-all;
}
.pcsAssignTitleStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.pcsAssignSubTitleStyle {
  color: var(--text-color);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.deviderStyle {
  display: none;
}
.assignesViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

@media screen and (max-width: 480px) {
  .takeActionWrapperStyle,
  .previewContentWrapperStyle {
    max-height: calc(100vh - 130px);
  }
  .taskInfoWrapperStyles,
  .taskCLWrapperStyle,
  .taskAssignWrapperStyle {
    flex-direction: column;
  }
  .takeActionMiddleWrapperStyle {
    padding: 0px;
    gap: 0px;
  }
  .taskInfoWrapperStyles,
  .taskCLWrapperStyle {
    padding: 20px;
    border-bottom: 1px solid var(--border-color);
  }
  .taskAssignWrapperStyle {
    padding: 20px;
  }
  .itemInputWrapperStyle {
    flex-direction: column;
    align-items: flex-start;
  }
  .headerBtnsViewStyle {
    width: 100%;
    padding: 0px 20px;
  }
  .headerBtnStyle,
  .contineuBtnStyle,
  .activeButtonStyle {
    width: 100%;
    padding: 12px 16px;
  }
  .errorTextStyle {
    padding: 0px 20px 10px 20px;
  }
}
