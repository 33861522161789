.userDetailsContainerStyle {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
.loaderViewStyle {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
}
.userInfoSectionStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.userInfoViewStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}
.userWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.avatarViewStyle {
  width: 48px;
  height: 48px;
  font-size: 24px;
  overflow: hidden;
}
.avatarViewStyle img {
  object-fit: cover;
}
.userDetailsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.titleTextStyles {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.descTextStyle {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.optionsBtnStyle {
  padding: 8px;
}
/* // user Assign UnAssign Style  */
.userAssetsWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.userAssetsListWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tabsWrapperStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}
.capsuleViewStyle {
  border-radius: 4px;
  background-color: var(--white);
}
.selectedCapsuleStyle {
  background-color: var(--bg-primary-color);
  border: none;
  border-radius: 4px;
}
.capsuleLableStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--desc-color40);
}
.selectedLableStyle {
  color: var(--text-color);
}
/* tab content view style  */
.tabContentViewStyle {
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
}
/* tab content view style end  */
/* overView content view style  */
.overViewContentSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 568px;
  width: 100%;
}
.overViewSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.overViewTitleWrapperStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.overViewTitleTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--text-color);
}
.overViewActionElementsWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.overViewLinkWrapperStyle {
  display: flex;
  align-items: center;
}
.overViewLinkTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2px;
  color: var(--primary-color);
  cursor: pointer;
}
.reminderCustomLinkTextStyle {
  color: var(--deep-purple-color);
}
.overViewLinkIconStyle {
  width: 16px;
  height: 16px;
}
.overViewBtnStyle {
  display: flex;
  gap: 4px;
}
.overViewBtnLeftIconStyle {
  width: 20px;
  height: 20px;
}
.overViewBodyWrapperStyle {
  display: flex;
  flex-direction: column;
}
.overViewBodyWrapperContentStyle {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.reminderCustomBtnStyle {
  color: var(--deep-purple-color);
  margin: auto;
}
/* overView content view style end  */

/* // table view Style  */
.tableSectionStyle {
  width: 100%;
  height: 100%;
}
.tableWrapperStyle {
  padding: 0;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  border-collapse: collapse;
  vertical-align: middle;
  width: 100%;
  /* overflow-x: scroll; */
}
.tableWrapperStyle td {
  width: 33%;
}
.lastElementViewStyle {
  text-align: center !important;
}
.tableHeaderRowStyle th {
  padding: 0px 16px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(30, 30, 32, 0.4);
  background: #ffffff;
  border-bottom: 1px solid var(--border-color);
  /* border-right: 1px solid var(--border-color); */
  text-align: left;
  white-space: nowrap;
}

.tableHeaderRowStyle th:last-child {
  border-right: none;
}
.tableHeaderRowStyle th:first-child,
.tableRowStyle td:first-child {
  padding-left: 0px;
}
.tableRowStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
}
.tableRowStyle td {
  padding: 10px 16px;
}
.customColStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.customDateColStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.assetIconWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
}
.assetIconStyle {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  overflow: hidden;
  padding: 4px;
  background-color: var(--bg-primary-color);
}
.tableTitleTextStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-color);
}
.tableSmallTextStyle {
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
  white-space: nowrap;
}
.dateColStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.assignedStyle {
  color: var(--primary-color);
}
.lostStyle {
  color: var(--error-color);
}
.unAssignedStyle {
  color: var(--desc-color60);
}
.emptyDataStyle {
  color: var(--desc-color60);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 16px;
  padding: 10px;
}
.actionBtnViewStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.btnStyle {
  position: relative;
}
.iconStyle {
  width: 20px;
  height: 20px;
}
.actionBtnStyle {
  background-color: var(--blue-ribbon-color);
  color: var(--primary-color);
}
.actionOptionsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 176px;
}
.optionsViewStyles {
  padding: 4px;
  width: 100%;
  border-radius: 4px;
  background: var(--lightGrayishBlue);
  color: var(--textDark);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  cursor: pointer;
}
.optionsViewStyles:hover {
  background-color: var(--bg-primary-color);
}
.actionPopUpViewStyle {
  top: -3% !important;
  bottom: unset;
  padding: 8px;
  background-color: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  border: 1px solid var(--border-color);
}
.alertModalViewStyle {
  width: 408px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.modalContentWrapperStyle {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.modalTitleStyle {
  font-family: 'InterTight', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-color);
}
.modalDescriptionStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--desc-color60);
}
.modalDescriptionStyle span {
  color: var(--primary-color);
}
.modalActionBtnWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.assetModalViewStyle {
  width: 470px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
/* mobile section Styles */
.currentlyAssignedMobileSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.lableViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.lableTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  color: var(--text-color);
}
.cardBottomSectionStyle {
  align-items: center !important;
}
.dropDownIconStyle {
  width: 10px;
  height: 12px;
  transition: all 0.5s ease-in-out;
}
.arrowRotateViewStyle {
  width: 10px;
  height: 12px;
  transform: rotate(180deg);
  transition: all 0.5s ease-in-out;
}
.backOptionViewStyle {
  display: none;
}
.backArrowStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.backTextStyle {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  color: var(--text-color);
}
.tableSectionStyle {
  width: 100%;
  height: 100%;
  display: flex;
}
.tableHeaderRowStyle th {
  padding: 0px 16px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(30, 30, 32, 0.4);
  background: #ffffff;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  text-align: left;
}
.tableHeaderRowStyle th:last-child {
  border-right: none;
}
.ticketDetailsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.emptyDataViewStyle {
  width: 100%;
  height: calc(100vh - 260px);
  background-color: var(--bg-primary-color);
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px dashed var(--desc-color40);
  padding: 16px;
}
.emptyDataSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.emptyDataTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}
.editModalViewStyle {
  position: fixed;
  top: 60px;
  left: unset;
  right: 20px;
  width: 720px;
  border-radius: 4px;
}
.editInfoBtnStyle,
.reminderBtnStyle {
  display: flex;
  gap: 4px;
}
.btnLeftIconStyle {
  width: 20px;
  height: 20px;
}
.editInfoTextStyle,
.addNewTextStyle {
  display: none;
}
/* delete asset modal styles  */
.alertDeleteModalViewStyle {
  width: 408px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
  z-index: 2;
}
.deleteModalContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.deleteModalTextWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.deleteTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--text-color);
}
.deleteModalDescStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color80);
}
.deleteModalDescStyle span {
  font-weight: 500;
  color: var(--primary-color);
  cursor: pointer;
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.yesBtnStyle {
  background-color: var(--error-color);
}
.errorTextStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--error-color);
}

/* reminder styles  */
.remaindersViewStyle {
  max-width: 568px;
  width: 100%;
  position: relative;
}
.labelWrapperStyle {
  width: 568px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.addNewBtnViewStyle {
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  align-self: center;
}
/* reminder modal styles  */
.reminderModalViewStyle {
  position: fixed;
  top: 60px;
  left: unset;
  right: 20px;
  width: 568px;
  border-radius: 4px;
}

/* attachments style  */
.editAttachmentsModalViewStyle {
  position: fixed;
  top: 60px;
  left: unset;
  right: 20px;
  width: 568px;
  border-radius: 4px;
}
.editAttachmentsViewStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.editAttachmentsHeaderStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}
.attachmentsHeaderTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-color);
}
.atttachmentsContentStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 20px;
}
.attachmentsLableStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.labelTextStyle {
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--desc-color60);
}
.infoIconStyle {
  width: 16px;
  height: 16px;
}
.attachmentsBtnStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.viewAllAndBtnWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.viewAllWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.viewAllTextStyle {
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--deep-purple-color);
}
.viewAllIconStyle {
  width: 16px;
  height: 16px;
}
.allRemaindersModalViewStyle {
  position: fixed;
  top: var(--header-height);
  left: unset;
  right: 20px;
  width: 568px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
  border: 1px solid var(--border-color);
}

@media screen and (max-width: 480px) {
  .userDetailsContainerStyle {
    padding: 0px;
    height: 100%;
    overflow-y: scroll;
  }
  .userInfoViewStyle,
  .userAssetsWrapperStyle {
    padding: 0px 20px;
  }
  .userDetailsViewStyle {
    gap: 0px;
  }
  .avatarViewStyle {
    width: 44px;
    height: 44px;
    font-size: 20px;
  }
  .titleTextStyles {
    font-size: 16px;
  }
  .actionPopUpViewStyle {
    height: fit-content !important;
  }
  .alertModalViewStyle {
    width: 90%;
  }
  .assetModalViewStyle {
    display: flex;
    height: 100%;
    width: 100%;
    position: fixed;
  }
  .backOptionViewStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    padding: 20px;
    gap: 8px;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    background-color: var(--white);
    top: 0px;
    z-index: 1;
  }
  /* Overview Tab Content start */
  .overViewBtnMobStyle {
    display: none;
  }
  /* Overview Tab Content end */

  .editInfoBtnStyle,
  .reminderBtnStyle {
    display: none;
  }
  .userInfoWrapperStyle {
    width: 100%;
  }
  .editInfoTextStyle {
    display: block;
  }
  .editModalViewStyle {
    display: flex;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    border-radius: 8px 8px 0px 0px;
  }
  .alertModalViewStyle {
    position: fixed !important;
    bottom: 0px !important;
    top: unset !important;
    left: unset !important;
    width: 100%;
    height: fit-content;
    padding: 8px 8px 16px 8px;
    transform: none !important;
    border-radius: 8px 8px 0px 0px;
  }

  .remaindersViewStyle {
    max-width: 100%;
    width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;
  }
  .reminderModalViewStyle {
    width: 100%;
    height: auto !important;
    border-radius: 8px 8px 0 0;
    display: flex;
    position: fixed;
    bottom: 0 !important;
    right: auto !important;
    /* top: auto !important; */
  }
  .userInfoWrapperStyle,
  .labelWrapperStyle {
    width: 100%;
  }
  .addNewBtnViewStyle {
    display: flex;
  }
  .addNewTextStyle {
    display: flex;
    font-family: 'Manrope', sans-serif;
    color: var(--deep-purple-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 2px;
    cursor: pointer;
  }
  .editAttachmentsModalViewStyle {
    display: flex;
    height: auto;
    width: 100%;
    position: fixed;
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    border-radius: 8px 8px 0px 0px;
  }
  .allRemaindersModalViewStyle {
    display: flex;
    height: auto;
    width: 100%;
    position: fixed;
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    border-radius: 8px 8px 0px 0px;
  }
}
