:root,
[data-theme='light'] {
  --white: #fff;
  --black: #000;
  --primary-color: #236bfe;
  --text-color: #1e1e20;
  --desc-color90: rgba(30, 30, 32, 0.9);
  --desc-color80: rgba(30, 30, 32, 0.8);
  --desc-color60: rgba(30, 30, 32, 0.6);
  --desc-color40: rgba(30, 30, 32, 0.4);
  --desc-color20: rgba(30, 30, 32, 0.2);
  --desc-color30: rgba(30, 30, 32, 0.3);
  --desc-color05: rgba(30, 30, 32, 0.05);
  --bg-primary-color: rgba(229, 236, 246, 1);
  --bg-secondary-color: rgba(246, 247, 249, 1);
  --light-mint-green-color: rgba(233, 246, 229, 1);
  --pale-mint-green-color: rgba(225, 245, 223, 0.12);
  --border-color: rgba(232, 232, 232, 1);
  --error-color: rgba(236, 0, 0, 1);
  --lavender-color: rgba(246, 229, 246, 1);
  --lavender-mist-color: rgba(244, 223, 245, 0.12);
  --pale-lavender-color: rgba(243, 233, 255, 1);
  --green-color: rgba(42, 198, 112, 1);
  --dark-green-color: rgba(0, 174, 77, 1);
  --dark-grayish-color: rgba(30, 30, 32, 0.5);
  --medium-gray-color: rgba(210, 210, 210, 1);
  --light-gray-color: rgba(246, 246, 246, 1);
  --magenta-rose-color: rgba(255, 0, 92, 1);
  --magenta-color: rgba(255, 0, 153, 1);
  --pale-pink-color: rgba(246, 229, 229, 1);
  --pale-pink-shade-color: rgba(246, 229, 233, 1);
  --pastel-green-clolor: rgba(229, 246, 237, 1);
  --light-yellow-color: rgba(246, 241, 229, 1);
  --orange-color: rgba(255, 107, 0, 1);
  --orange-color10: rgba(255, 107, 0, 0.1);
  --deep-purple-color: rgba(131, 35, 254, 1);
  --purple-color10: rgba(131, 35, 254, 0.1);
  --peach-color: rgba(255, 241, 230, 1);
  --amber-orange-color: rgba(255, 184, 0, 1);
  --cosmic-latte-color: rgba(255, 248, 230, 1);
  --border-pale-yellow-color: rgba(255, 242, 207, 1);
  --mint-green-color: rgba(222, 240, 233, 1);
  --border-pale-aqua-hue-color: rgba(200, 233, 218, 1);
  --light-pink: rgba(254, 230, 230, 1);
  --border-light-pink-shade: rgba(253, 218, 218, 1);
  --shadow-color08: rgba(30, 30, 32, 0.08);
  --shadow-color16: rgba(30, 30, 32, 0.16);
  --light-gray-shadow-color012: rgba(196, 196, 196, 0.12);
  --steel-gray-color: rgba(137, 143, 150, 1);
  --btn-primary-shadow-color: rgba(35, 107, 254, 0.12);
  --soft-verdant-mint-color: rgba(0, 174, 77, 0.1);
  --dark-gold-color: rgb(230, 149, 0);
  --light-shade-gray-color: rgba(234, 235, 238, 1);
  --blue-ribbon-color: rgba(35, 107, 254, 0.1);
  --dodger-blue-color10: rgba(35, 107, 254, 0.4);
}
[data-theme='dark'] {
  --white: #000;
  --black: #fff;
  --primary-color: #236bfe;
  --text-color: #1e1e20;
  --desc-color90: rgba(30, 30, 32, 0.9);
  --desc-color80: rgba(30, 30, 32, 0.8);
  --desc-color60: rgba(30, 30, 32, 0.6);
  --desc-color40: rgba(30, 30, 32, 0.4);
  --desc-color20: rgba(30, 30, 32, 0.2);
  --desc-color05: rgba(30, 30, 32, 0.05);
  --bg-primary-color: rgba(229, 236, 246, 1);
  --bg-secondary-color: rgba(246, 247, 249, 1);
  --light-mint-green-color: rgba(233, 246, 229, 1);
  --pale-mint-green-color: rgba(225, 245, 223, 0.12);
  --border-color: rgba(232, 232, 232, 1);
  --error-color: rgba(236, 0, 0, 1);
  --lavender-color: rgba(246, 229, 246, 1);
  --lavender-mist-color: rgba(244, 223, 245, 0.12);
  --pale-lavender-color: rgba(243, 233, 255, 1);
  --green-color: rgba(42, 198, 112, 1);
  --dark-green-color: rgba(0, 174, 77, 1);
  --dark-grayish-color: rgba(30, 30, 32, 0.5);
  --medium-gray-color: rgba(210, 210, 210, 1);
  --light-gray-color: rgba(246, 246, 246, 1);
  --magenta-rose-color: rgba(255, 0, 92, 1);
  --magenta-color: rgba(255, 0, 153, 1);
  --pale-pink-color: rgba(246, 229, 229, 1);
  --pale-pink-shade-color: rgba(246, 229, 233, 1);
  --pastel-green-clolor: rgba(229, 246, 237, 1);
  --light-yellow-color: rgba(246, 241, 229, 1);
  --orange-color: rgba(255, 107, 0, 1);
  --orange-color10: rgba(255, 107, 0, 0.1);
  --deep-purple-color: rgba(131, 35, 254, 1);
  --purple-color10: rgba(131, 35, 254, 0.1);
  --peach-color: rgba(255, 241, 230, 1);
  --amber-orange-color: rgba(255, 184, 0, 1);
  --cosmic-latte-color: rgba(255, 248, 230, 1);
  --border-pale-yellow-color: rgba(255, 242, 207, 1);
  --mint-green-color: rgba(222, 240, 233, 1);
  --border-pale-aqua-hue-color: rgba(200, 233, 218, 1);
  --light-pink: rgba(254, 230, 230, 1);
  --border-light-pink-shade: rgba(253, 218, 218, 1);
  --shadow-color08: rgba(30, 30, 32, 0.08);
  --shadow-color16: rgba(30, 30, 32, 0.16);
  --light-gray-shadow-color012: rgba(196, 196, 196, 0.12);
  --steel-gray-color: rgba(137, 143, 150, 1);
  --btn-primary-shadow-color: rgba(35, 107, 254, 0.12);
  --soft-verdant-mint-color: rgba(0, 174, 77, 0.1);
  --dark-gold-color: rgb(230, 149, 0);
  --light-shade-gray-color: rgba(234, 235, 238, 1);
  --blue-ribbon-color: rgba(35, 107, 254, 0.1);
  --dodger-blue-color10: rgba(35, 107, 254, 0.4);
}
