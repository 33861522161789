.cdMainContainerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.cdContainerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.cdHeaderBlockStyle {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-secondary-color);
}
.cdHeaderLeftBlockStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.cdHeaderLeftBlockIconStyle {
  width: 24px;
  height: 24px;
}
.cdHeaderLeftBlockContentStyle {
  display: flex;
  flex-direction: column;
}
.cdHeaderLeftBlockTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.cdHeaderLeftBlockDescStyle {
  color: var(--desc-color40);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.cdHeaderLeftBlockDescStyle span {
  color: var(--desc-color80);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.cdHeaderBlockRightStyle {
  display: flex;
  gap: 4px;
  align-items: center;
}
.cdMenuOptionsModalStyle {
  width: 170px;
  padding: 8px;
  top: -20px !important;
  bottom: unset;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.optionsViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.eachOptionViewStyle {
  display: flex;
  flex-direction: row;
  padding: 4px 0px 4px 8px;
  border-radius: 4px;
}
.eachOptionViewStyle:hover {
  background-color: var(--bg-primary-color);
  cursor: pointer;
}
.optionTextStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.emptySectionViewStyle {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.emptyDataTextStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--desc-color60);
}
.notSelectedSectionViewStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}
.notSelectedTextStyle {
  color: var(--desc-color60);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  padding: 8px 16px;
  background-color: var(--bg-secondary-color);
  border-radius: 4px;
}
.notSelectedTextStyle span {
  color: var(--primary-color);
  text-decoration-line: underline;
  cursor: pointer;
}
.chatBoxContainerStyle {
  height: calc(100% - 124px);
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
}
.chatBoxSubContainerStyle {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.leftMsgWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-self: flex-start;
  gap: 10px;
}
.rightMsgWrapperStyle {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  align-self: flex-end;
  gap: 10px;
}
.msgTimeStampStyle {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--desc-color40);
}
.leftmsgTextWrapperStyle {
  max-width: 60%;
  padding: 6px 12px;
  background-color: var(--bg-secondary-color);
  border-radius: 12px;
  word-break: break-all;
  text-align: start;
}
.rightmsgTextWrapperStyle {
  max-width: 60%;
  align-self: flex-start;
  padding: 6px 12px;
  background-color: var(--bg-primary-color);
  border-radius: 12px;
  word-break: break-all;
  text-align: start;
}
.leftmsgTextStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-color);
}
.rightmsgTextStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-color);
}
.dateWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 16px 0px;
}
.dateViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: var(--bg-secondary-color);
  width: fit-content;
}
.chatInputContainerStyle {
  width: 100%;
  padding: 8px 20px 16px 20px;
  height: 64px;
}
.inputViewStyle {
  border: none;
  background-color: var(--bg-secondary-color);
  border-radius: 24px;
  padding-left: 45px !important;
}
.inputLeftIconStyle {
  left: 25px;
  cursor: pointer;
}
.inputRightIconStyle {
  right: 25px;
  cursor: pointer;
}
.modalViewStyle {
  width: 470px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.alertModalBtnStyle {
  width: 100%;
  justify-content: flex-end;
}
.assetReturnedViewStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: var(--orange-color10);
  padding: 12px;
  border-radius: 8px;
  margin-top: 18px;
}
.assetRecoveredViewStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: var(--purple-color10);
  padding: 12px;
  border-radius: 8px;
  margin-top: 18px;
}
.imgViewStyle {
  width: 25px;
  height: 25px;
}
.assetStatusTextStyle,
.assetStatusTextStyle2 {
  color: var(--black);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.assetStatusTextStyle span {
  color: var(--desc-color60);
}
.onClickTextStyle2 {
  color: var(--orange-color);
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.onClickTextStyle {
  color: var(--deep-purple-color);
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
