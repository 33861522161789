.containerStyle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.color1Style {
  background-color: var(--lavender-color);
  color: var(--text-color);
  font-weight: bold;
}
.color2Style {
  background-color: var(--light-mint-green-color);
  color: var(--text-color);
  font-weight: bold;
}
.color3Style {
  background-color: var(--bg-primary-color);
  color: var(--text-color);
  font-weight: bold;
}
.color4Style {
  background-color: var(--peach-color);
  color: var(--text-color);
  font-weight: bold;
}
.color5Style {
  background-color: var(--pale-lavender-color);
  color: var(--text-color);
  font-weight: bold;
}