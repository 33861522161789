.selectWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.labelTextStyle {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}
.containerStyle {
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.containerStyle2 {
  border: none;
}
.errorTextStyle {
  color: var(--error-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
