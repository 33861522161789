.containerStyle {
  border: 1px solid var(--border-color);
  width: fit-content;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 18px;
  background-color: var(--bg-secondary-color);
}
.labelStyle {
  color: var(--text-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.leftIconStyle {
}
.rightIconStyle {
}
