.searchBoxWrapperStyle {
  display: flex;
  width: 586px;
  flex-direction: column;
  border-radius: 8px;
  height: auto;
  max-height: calc(100vh - 120px);
  overflow-y: scroll;
}
.searchInputWrapperStyle {
  width: 100%;
  display: flex;
  padding: 4px;
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 0px;
  background: var(--white);
}
.searchInputStyle {
  border: none;
}
.searchResultsWrapperStyle {
  display: flex;
  flex-direction: column;
}
.eachSectionStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}
.lableTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--desc-color60);
}
.lableTextStyle span {
  width: 15px;
  height: 16px;
  padding: 2px 4px;
  border-radius: 2px;
  background-color: var(--bg-secondary-color);
  border: 0.5px solid var(--border-color);
  margin-left: 4px;
}
.filterOptionsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
.capsuleLableStyle {
  font: 500 14px/20px 'Manrope', sans-serif !important;
  color: var(--desc-color40) !important;
}
.capsuleViewStyle {
  border-radius: 4px !important;
  background-color: var(--white) !important;
}
.activeOptionTextStyle {
  color: var(--text-color) !important;
}
.activeOptionStyle {
  background-color: var(--light-mint-green-color) !important;
}

/* users section  */
.usersViewStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 14px; */
  width: 100%;
}
.blockListItemStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-block: 2px;
  width: 100%;
  padding: 8px 0px;
}
.blockListItemStyle:hover {
  background-color: var(--light-mint-green-color);
  border-radius: 4px;
}
.blockListItemUserIconStyle {
  min-width: 36px;
  padding: 8px;
}
.blockListItemTitleStyle {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.blockLinkStyle {
  width: fit-content;
  color: var(--desc-color40);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 20px;
  text-decoration: none;
}
.blockLinkStyle:hover {
  color: var(--primary-color);
}
/* asset list  */
.blockListItemAssetIconStyle {
  min-width: 36px;
  padding: 8px;
  background-color: var(--bg-primary-color);
}
.blockListItemTitleStyle {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 480px) {
  .searchBoxWrapperStyle {
    width: 100%;
    max-height: calc(100vh - 60px);
  }
}
