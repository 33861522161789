.switchContainerStyle {
  min-width: 36px;
  height: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: var(--desc-color40);
  border-radius: 16px;
  padding: 2px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.switchViewStyle {
  width: 16px;
  height: 16px;
  background-color: var(--white);
  border-radius: 100%;
  transition: 0.2s ease-in-out;
}
.checkedStyle {
  justify-content: flex-end;
  transition: 0.2s ease-in-out;
  background: var(--primary-color);
}
