/********   fonts  *********/
@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope.ttf');
}
@font-face {
  font-family: 'InterTight';
  src: url('./assets/fonts/InterTight.ttf');
}
@font-face {
  font-family: 'InterTight-Italic';
  src: url('./assets/fonts/InterTight-Italic.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}
:root {
  --header-height: 60px;
}

/* colors */
@import url('assets/css/theme-colors.css');

.Toastify__toast {
  padding: 0px !important;
  min-height: 40px !important;
  background-color: var(--desc-color40) !important;
}
.Toastify__toast-body {
  margin: 0px !important;
  padding: 0px !important;
}
@media screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 8px !important;
    margin-bottom: 1rem !important;
  }
  .Toastify__toast-container {
    width: 90% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}
