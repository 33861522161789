.mainContainerStyle {
  max-width: 389px;
  width: 100%;
  height: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  position: relative;
}
.inputContainerStyle {
  width: 389px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  gap: 24px;
  border-radius: 8px;
}
.inputSubContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.signUpGoogleBtnStyles {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}
.nextStepBtnStyles {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}
.signUpTopStyles {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}
.signUpBlockStyles {
  display: none;
}
.titleTestStyle {
  font-family: 'InterTight';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: var(--text-color);
}
.inputwrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.lableTextStyle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
}
.otpContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.errorTextStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--error-color);
  margin-bottom: 16px;
}
.paginationContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.dotBtnStyle {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background: var(--border-color);
}
.activeDotBtnStyle {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background: var(--bg-primary-color);
}
.signUpBottomStyles {
  display: block;
}
.haveAnAccountStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.haveAnAccountTextStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--desc-color60);
}
.loginTextStyle {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;
}
.resendTextContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
}
.resendTextStyle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: var(--primary-color);
}
.orTextStyle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
}
.resendEditEmailTextViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--desc-color60);
}
.resendEditEmailTextViewStyle span {
  cursor: pointer;
  text-decoration: underline;
  color: var(--primary-color);
}
.phoneNoLabelStyles {
  color: var(--desc-color60);
}
.btnWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.proceedBtnStyles {
  width: 100%;
  background-color: var(--text-color);
  text-align: center;
  padding: 12px 24px;
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
  position: relative;
}
.loaderStyle {
  width: 30px;
  height: 30px;
}
.googleBtnStyles {
  width: 100%;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--white);
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.googleIconStyle {
  width: 20px;
  height: 20px;
}

/* pagination style  */
.paginationContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.dotBtnStyle {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background: var(--border-color);
}
.activeDotBtnStyle {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background: var(--primary-color);
}
@media screen and (max-width: 480px) {
  .mainContainerStyle {
    max-width: 100%;
    width: 100%;
  }
  .inputContainerStyle {
    max-width: 100%;
    width: 100%;
    padding: 16px;
  }
}
