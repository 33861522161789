.headerWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  background-color: var(--bg-secondary-color);
}
.headerSubWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.backIconStyle {
  display: none;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.headerLeftWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.headerRightWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.headerTextStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.headerBtnStyle {
  padding: 4px 8px;
  border-radius: 4px;
}
.noActionVtnStyle {
  cursor: default;
}
.dropDownIconStyle {
  width: 10px;
}
.iconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.menuIconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
}
.activityContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px 20px;
  overflow: scroll;
  margin-bottom: 16px;
}
.activitySubContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tabsWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
}
.statusViewStyle {
  display: flex;
  align-items: center;
  position: relative;
}
.optionsViewStyle {
  width: 100%;
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.ticketStatusOptionsViewStyle {
  width: 320px;
  bottom: unset;
  padding: 8px;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.ticketStatusContainerStyle {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 8px 0px;
  border-radius: 4px;
  position: relative;
}
.optionViewStyle,
.optioViewStyle2 {
  width: 100%;
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.optioViewStyle2 {
  border-top: 1px solid var(--border-color);
}
.selectedOptionViewStyle {
  background-color: var(--bg-secondary-color);
}
.disabledViewStyle {
  opacity: 0.5;
}
.detailsViewStyle {
  display: flex;
  flex-direction: column;
}
.titleTextStyle {
  overflow: hidden;
  color: var(--textDark);
  text-overflow: ellipsis;
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.descTextStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 17px;
}
.customCheckBoxStyle {
  width: 16px;
  height: 16px;
  min-width: 16px;
  border-radius: 4px;
  border: 1px solid var(--desc-color20);
  background: var(--white);
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
.customCheckBoxStyle:checked {
  background: #236bfe;
  position: relative;
}
.disabledCheckBoxStyle {
  opacity: 0.4;
}
.customCheckBoxStyle:checked::before {
  content: url('assets/images/checkIcon.svg');
  position: absolute;
  left: 2px;
  top: -3px;
  width: 16px;
  height: 16px;
}
.disabledViewStyle {
  opacity: 0.5;
}
.pinkBgStyle {
  background-color: var(--magenta-rose-color) !important;
}
.grayBgStyle {
  background-color: var(--desc-color40) !important;
}
.primaryBgStyle {
  background-color: var(--primary-color) !important;
}
.greenBgStyle {
  background-color: var(--green-color) !important;
}
.orangeBgStyle {
  background-color: var(--orange-color) !important;
}
.lightGrayBgStyle {
  background-color: var(--medium-gray-color) !important;
}
.blackBgStyle {
  background-color: var(--text-color) !important;
}
.redBgStyle {
  background-color: var(--error-color) !important;
}
.overlayViewStyle {
  z-index: 1;
}
.alertModalViewStyle {
  width: 320px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
  z-index: 1;
}
.alertModalTitleTextStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.alertBoxStyle {
  width: 320px;
  padding: 16px;
}
.alertBoxBtnsStyle {
  justify-content: flex-end;
}
.alertBoxLeftBtnStyle {
  color: var(--text-color);
  background-color: var(--white);
  width: fit-content;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
}
.alertBoxRightBtnStyle {
  color: var(--white);
  width: fit-content;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
}
.taskModalViewStyle {
  position: fixed !important;
  top: 50% !important;
  right: 2% !important;
  left: unset !important;
  transform: translateY(-50%) !important;
}
.taskModalTopSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.taskModalStyle {
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  gap: 16px;
  width: 568px;
}
.taskModalHeaderStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  padding: 0px 20px 16px 20px;
}
.closeIconViewStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.taskMoadlDetailsViewStyle {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  gap: 16px;
}
.taskMsgViewStyle {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--bg-secondary-color);
}
.msgTextStyle {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.textAreaViewStyle {
  width: 100%;
  height: 96px;
  resize: none;
}
.addCommentLabelStyle {
  color: var(--desc-color60);
}
.taskModalBtnViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 0px 20px;
}
.taskBtnViewStyle {
  padding: 8px 12px;
}
.menuWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.membersModalViewStyle {
  width: 468px;
  bottom: unset;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.menuOptionsModalViewStyle {
  top: -3% !important;
  bottom: unset;
  padding: 8px;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.menuOptionViewStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 24px 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.menuOptionViewStyle:hover {
  background-color: var(--bg-primary-color);
}
.menuOptionSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.menuOptionIconStyle {
  width: 20px;
  height: 20px;
}
.menuOptionTextStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--text-color);
}
.deviderStyle {
  display: none;
}
@media screen and (max-width: 480px) {
  .headerWrapperStyle {
    padding: 8px 16px;
  }
  .headerLeftWrapperStyle {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }
  .backIconStyle {
    display: flex;
  }
  .menuWrapperStyle {
    gap: 16px;
  }
  .menuOptionsModalViewStyle {
    position: fixed !important;
    bottom: 0px !important;
    top: unset !important;
    width: 100%;
    height: fit-content;
    padding: 8px 8px 16px 8px;
    transform: none !important;
    border-radius: 8px 8px 0px 0px;
  }
  .deviderStyle {
    display: flex;
    width: 40px;
    height: 4px;
    gap: 0px;
    border-radius: 2px;
    opacity: 0px;
    background: var(--desc-color30);
    align-self: center;
    margin-bottom: 4px;
  }
  .membersModalViewStyle,
  .ticketStatusOptionsViewStyle {
    position: fixed !important;
    width: 100%;
    top: unset !important;
    bottom: 0px !important;
    height: fit-content;
    transform: none !important;
    border-radius: 8px 8px 0px 0px;
  }
  .taskModalViewStyle {
    display: flex;
    height: 100%;
    width: 100%;
    position: fixed;
    right: 0 !important;
    border-radius: 8px 8px 0px 0px;
  }
  .taskModalStyle {
    width: 100%;
    justify-content: space-between;
  }
  .taskModalBtnViewStyle,
  .taskBtnViewStyle {
    width: 100%;
  }
}
