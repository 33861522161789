.multiSelectContsinerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.multiSelectInputStyle {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--white);
  position: relative;
  padding: 0px 12px;
}
.multiSelectInputStyle2 {
  padding: 12px 12px;
}
.lableStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.inputCustomStyle {
  border: none;
  padding: 0px;
}
.optionsModalViewStyle {
  position: absolute;
  top: 102%;
  left: 0;
  right: unset;
  z-index: 2;
}
.optionsViewStyle {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px var(--light-gray-shadow-color012);
  width: 320px;
  border: 1px solid var(--border-color);
  overflow: scroll;
  max-height: 250px;
  overflow-x: hidden;
}

.optionsViewStyle::-webkit-scrollbar {
  width: 5px;
  display: block;
}

/* Track */
.optionsViewStyle::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--white);
  border-radius: 5px;
}

/* Handle */
.optionsViewStyle::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}
.optionsViewStyle::-webkit-scrollbar-thumb:hover {
  background: grey;
}
.eachOptionViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
}
.eachOptionViewStyle:hover {
  background-color: var(--bg-primary-color);
  border-radius: 8px;
}
.selectedOptionViewStyle {
  background-color: var(--light-mint-green-color);
  border-radius: 8px;
}
.optionImageViewStyle {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}
.optionDetailsViewStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.optionTitleTextStyle {
  overflow: hidden;
  color: var(--text-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.optionDescTextStyle {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 16px;
}
.emptyDataViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
}
.selectedOptionsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
.selectedCardViewStyle {
  width: fit-content;
}
.selectedCardViewStyle2 {
  width: fit-content;
  background-color: var(--bg-primary-color);
}
.iconStyle {
  width: 16px;
  height: 16px;
}
@media screen and (max-width: 480px) {
  .eachOptionViewStyle {
    padding: 8px 12px;
  }
  .optionsModalViewStyle {
    height: fit-content;
  }
}
