.containerStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 120px);
  height: 100%;
  position: relative;
  padding-bottom: 20px;
}
.headerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}
.headerTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-color);
}
.headerBtnsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.contentWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  justify-content: space-between;
  overflow: scroll;
}
.contentWrapperStyle {
  scrollbar-width: auto;
}
.contentWrapperStyle::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}
.contentWrapperStyle::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  cursor: pointer;
  border-radius: 4px;
}
.contentSubWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.textStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--desc-color60);
  width: 40%;
}
.formTitleStyle {
  font-family: 'InterTight';
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-color);
}
.inputWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.inputSectionViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.selectTypeInputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.lableTextStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.valueTextStyle {
  color: var(--text-color);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
}
.placeHolderTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color40);
}
.selectTypeContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
}
.arrowDownIconStyle {
  width: 12px;
  height: 12px;
}
/* popover styles  */
.actionOptionsViewStyle {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 4px;
  width: 288px;
  overflow: scroll;
  max-height: 120px;
  border-bottom: 1px solid var(--border-color);
}

.actionPopUpViewStyle {
  max-height: 174px;
  padding: 8px;
  background-color: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  border: 1px solid var(--border-color);
}
.optiondTextWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  border-radius: 4px;
}
.optiondTextWrapperStyle:hover {
  background-color: var(--bg-primary-color);
}
.optionsViewStyles {
  width: 100%;

  background: var(--lightGrayishBlue);
  color: var(--textDark);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  cursor: pointer;
}
.customOptionsViewStyles {
  padding: 4px;
  width: 100%;
  border-radius: 4px;
  background: var(--lightGrayishBlue);
  color: var(--primary-color);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  cursor: pointer;
}

.deleteOptionViewStyle {
  color: var(--error-color);
}
.checkIconStyle {
  width: 16px;
  height: 16px;
}
.customTypeContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}
.listIconStyle {
  width: 20px;
  height: 20px;
}
.inputPopUpViewStyle {
  /* top: -5% !important; */
  bottom: unset;
  padding: 8px;
  background-color: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  border: 1px solid var(--border-color);
}
.customEmployeeViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 288px;
  padding: 8px;
}
.overlayStyle {
  z-index: 1;
}
.popUpBtnContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
}

/* popover styles  */
.datePickerStyle {
  width: 100%;
  height: 44px;
  border-radius: 8px;
}
.dateIconStyle {
  width: 20px;
  height: 20px;
}
.dateValueStyle {
  color: var(--steel-gray-color);
}
.textAreaStyle {
  height: auto;
}
.categoryWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.labelTextStyle {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
}
.categoryInnerWrapperStyle {
  background: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  width: 100%;
}
.categoryBtnWrapperStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.categoryBtnStyle {
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 8px;
  color: var(--desc-color40);
  box-shadow: none;
}
.selectedCategoryBtnStyle {
  background-color: var(--light-mint-green-color);
  border-color: transparent;
  color: var(--text-color);
}
.verticalLineStyle {
  border-right: 1px solid var(--border-color);
  height: 100%;
}
.categroyCloseIconStyle {
  width: 15px;
  height: 15px;
}
.newCategoryInputWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.addCategoryBtnViewStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.categoryBtnDarkStyle {
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 8px;
  color: var(--text-color);
  box-shadow: none;
}
.submitBtnWrapperStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.btnsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.inputStyle {
  resize: none;
}
.closeIconStyle {
  display: none;
}
.mobileViewBtnsViewStyle {
  display: none;
}
.errorTextStyle {
  color: var(--error-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.disableinputStyle {
  background-color: var(--bg-secondary-color);
  color: var(--desc-color60);
}
@media screen and (max-width: 480px) {
  .containerStyle {
    max-height: 100%;
    height: 100%;
  }
  .formWrapperStyle {
    padding: 16px;
  }
  .formWrapperStyle {
    padding: 16px;
  }
  .inputSectionViewStyle {
    display: flex;
    flex-direction: column;
  }
  .newCategoryInputWrapperStyle {
    flex-direction: column;
  }
  .addCategoryBtnViewStyle {
    justify-content: flex-end;
  }
  .contentWrapperStyle {
    width: 100%;
    overflow: scroll;
  }
  .textStyle,
  .headerBtnsViewStyle {
    display: none;
  }
  .closeIconStyle,
  .mobileViewBtnsViewStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
  }
}
