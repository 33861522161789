.mainContainerStyle {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
}
.containerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.emailDetailsViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.headerViewStyle {
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0px;
  gap: 10px;
  border-bottom: 1px solid #e8e8e8;
}
.backImgViewStyle {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgStyle {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: cover;
}
.emailDetailsSubViewStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.titleTextStyle {
  color: var(--textDark);
  font-family: 'InterTight';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.descTextStyle {
  color: var(--darkBlueOpacity8);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.emailDescViewStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--aliceBlue);
  width: fit-content;
}
.assetNameTextStyle {
  color: var(--textDark);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.nextBtnViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.emptySectionViewStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.emptyDataTextStyle {
  color: var(--gray60);
  text-align: center;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.chatInputAndSendSectionStyle {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 24px;
  padding: 0px 20px;
  margin-bottom: 10px;
}
.chatInputSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
}
.inputViewStyle {
  border: none;
  background-color: var(--bg-secondary-color);
  border-radius: 24px;
  padding-left: 45px !important;
}
.customImageContainerStyles {
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}
.inputRightIconViewStyle {
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}
.chatViewStyle {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.chatViewStyle .chat__message {
  max-width: 50%;
  width: fit-content;
  border-radius: 8px;
  padding: 10px;
  margin: 2px 0px 2px 0px;
}
.chat__message .chat__messageImg {
  max-height: 100px;
  max-width: 100px;
  object-fit: cover;
  min-width: 100px;
  min-height: 100px;
}
.chat__message.leftStyle {
  position: relative;
  background-color: var(--bg-secondary-color);
}
.chat__message.leftStyle .chat__messageText {
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  /* margin-bottom: 10px; */
  text-wrap: wrap;
}
.chat__message.leftStyle {
  font-size: 10px;
  color: #404040;
  letter-spacing: 0.5px;
  align-self: flex-start;
}
.chat__message.rightStyle {
  position: relative;
  place-self: flex-end;
  background-color: var(--bg-primary-color);
}
.chat__message.rightStyle .chat__messageText {
  font-size: 16px;
  line-height: 24px;
  color: var(--textDark);
  text-wrap: wrap;
}
.chat__message.rightStyle {
  display: flex;
  font-size: 10px;
  color: var(--text-color);
  letter-spacing: 0.5px;
  width: fit-content;
  align-self: flex-end;
}
.msgTimeViewStyle {
  display: flex;
  flex-direction: row;
}
.msgTimeViewStyle.leftStyle .chatMessageTime {
  color: rgba(30, 30, 32, 0.4);
  font-family: 'Manrope';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding-bottom: 4px;
  align-self: flex-start;
}
.msgTimeViewStyle.rightStyle .chatMessageTime {
  color: rgba(30, 30, 32, 0.4);
  font-family: 'Manrope';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding-bottom: 4px;
  align-self: flex-end;
}
.leftStyle {
  display: flex;
  justify-content: flex-start;
}
.rightStyle {
  display: flex;
  justify-content: flex-end;
}
.assetReturnedViewStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: var(--orange-color10);
  padding: 12px;
  border-radius: 8px;
  margin-top: 18px;
}
.assetRecoveredViewStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: var(--purple-color10);
  padding: 12px;
  border-radius: 8px;
  margin-top: 18px;
}
.imgViewStyle {
  width: 50px;
  height: 25px;
}
.assetStatusTextStyle,
.assetStatusTextStyle2 {
  color: var(--text-color);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.assetStatusTextStyle span {
  color: var(--desc-color60);
}
.onClickTextStyle2 {
  color: var(--orange-color);
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.onClickTextStyle {
  color: var(--deep-purple-color);
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.tenSecTextStyle {
  color: var(--deep-purple-color);
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.chatMessgeGroupdate {
  width: fit-content;
  margin: auto;
  margin-bottom: 24px;
  padding: 8px 16px;
  border-radius: 4px;
  background: #f6f7f9;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: rgba(35, 31, 32, 0.72);
}
.cdMenuOptionsModalStyle {
  width: 170px;
  padding: 8px;
  top: -20px !important;
  bottom: unset;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.optionsViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.eachOptionViewStyle {
  display: flex;
  flex-direction: row;
  padding: 4px 0px 4px 8px;
  border-radius: 4px;
}
.eachOptionViewStyle:hover {
  background-color: var(--bg-primary-color);
  cursor: pointer;
}
.optionTextStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.modalViewStyle {
  position: fixed;
  bottom: 0;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  top: unset !important;
}
.modalBtnsViewStyle {
  width: 100%;
}
.modalBtnStyle {
  width: 100%;
}
