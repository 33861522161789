.containerStyle {
  position: relative;
}

.svgStyle {
  transform: rotate(-90deg);
}
.svgIndicatorSpinnerStyle {
  animation: spinner 0.75s linear infinite;
  transform-origin: center;
}

.labelStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.labelLoadingStyle {
  opacity: 0.5;
  font-size: 0.75em;
}

.labelProgressStyle {
  font-size: 1.5em;
  font-weight: bold;
}

.labelLoadingStyle,
.labelProgressStyle {
  display: block;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
