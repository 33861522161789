.cdMainContainerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.cdContainerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.cdHeaderBlockStyle {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-secondary-color);
}
.cdHeaderLeftBlockStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.cdHeaderLeftBlockIconStyle {
  min-width: 24px;
  min-height: 24px;
}
.cdHeaderLeftBlockContentStyle {
  display: flex;
  flex-direction: column;
}
.cdHeaderLeftBlockTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.cdHeaderLeftBlockDescStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.cdHeaderBlockRightStyle {
  display: flex;
  gap: 4px;
  align-items: center;
}
.cdBodyBlockStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  padding-bottom: 0;
  gap: 16px;
  overflow-y: scroll;
}
.cdBodyHeaderBlockStyle {
  display: flex;
  align-items: center;
  gap: 4px;
}
.selectedCapsuleSyle {
  background-color: var(--bg-primary-color);
  border-color: transparent;
}
/* sort and export section styles  */
.sortExportContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.sortSectionWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  border: 1px solid var(--border-color);
  cursor: pointer;
}
.sortTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color60);
}
.selectedSortStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.downIconStyle {
  width: 12px;
  height: 12px;
}
.exportWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}
.exportIconStyle {
  width: 16px;
  height: 16px;
}
.exportTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: var(--primary-color);
}
/* sort popup styles  */
/* sort asset modal styles  */
.sortModalStyle {
  position: absolute;
  border-radius: 4px;
  top: -5% !important;
  bottom: unset;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.sortModalContainerStyle {
  display: flex;
  flex-direction: column;
  width: 320px;
}
.sortByDateContainerStyle {
  display: flex;
  flex-direction: column;
}
.sortCustomHeaderContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--border-color);
}
.sortBackBtnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}
.backIconStyle {
  width: 20px;
  height: 20px;
}
.backTextStyle {
  color: var(--text-color);
  font-family: 'Manrope';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.imageWrapperStyle {
  width: 20px;
  height: 20px;
}
.closeIconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.sortByDateBtnStyle {
  width: 59px;
  height: 36px;
  padding: 8px 12px;
  border-radius: 4px;
}
.sortBtnStyle {
  display: none;
}
.sortModalIndividualSelectStyle {
  display: flex;
  flex-direction: column;
}
.sortLabelTextStyle {
  overflow: hidden;
  color: var(--text-color);
  text-overflow: ellipsis;
  font-family: 'InterTight';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 16px;
  cursor: pointer;
  white-space: nowrap;
}
.sortLabelTextStyle:hover {
  overflow: hidden;
  color: var(--text-color);
  text-overflow: ellipsis;
  font-family: 'InterTight';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 16px;
  cursor: pointer;
  background: var(--bg-primary-color);
  white-space: nowrap;
}
.sortLabelTextSelectedStyle {
  overflow: hidden;
  color: var(--text-color);
  text-overflow: ellipsis;
  font-family: 'InterTight';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 16px;
  cursor: pointer;
  background: var(--bg-primary-color);
  white-space: nowrap;
}

.sortOnDateContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 16px 16px 8px 16px;
}

.inputTypeDateStyle {
  gap: 4px;
  white-space: nowrap;
  padding: 8px 12px;
}
/* sort styles end  */
.cdBodyContentStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.noDataBlockStyle {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noDataTitleStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.clusterCreateModalStyle {
  width: 568px;
  max-height: calc(100% - 2 * var(--header-height));
  position: fixed;
  top: var(--header-height);
  left: 50%;
  transform: translateX(-50%);
}
.menuOptionsModalViewStyle {
  top: -3% !important;
  bottom: unset;
  padding: 8px;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.menuWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.menuOptionViewStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 24px 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.menuOptionViewStyle:hover {
  background-color: var(--bg-primary-color);
}
.menuOptionSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.menuOptionIconStyle {
  width: 20px;
  height: 20px;
}
.menuOptionTextStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--text-color);
}
.deviderStyle {
  display: none;
}
.archiveModalStyle {
  position: fixed;
  right: unset !important;
  top: 50% !important;
  left: 50%;
  border-radius: 8px;
  display: flex;
  height: auto;
  width: 300px;
  transform: translate(-50%, -50%);
}
.archiveAlertViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  position: relative;
}
.archiveTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--desc-color80);
}
.archiveTextStyle span {
  color: var(--text-color);
  font-weight: 500;
}
.archiveModalBtnsStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.archiveBtnStyle {
  width: fit-content;
  padding: 8px 12px;
  gap: 4px;
  background-color: var(--error-color);
}
.cancelBtnStyle {
  width: fit-content;
  padding: 8px 12px;
  gap: 4px;
  background-color: var(--white);
}
.clusterInfoModalStyle {
  width: 500px;
  max-height: calc(100% - 2 * var(--header-height));
  position: fixed;
  top: var(--header-height);
  left: unset !important;
  right: 20px !important;
}
.membersModalStyle {
  width: 468px;
  bottom: unset;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.optionsIconStyle {
  min-width: 20px;
  min-height: 20px;
}
.disableActionStyle {
  pointer-events: none;
  opacity: 0.6;
}

/* comments start */
.commentsBlcokStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
  max-height: 250px;
  height: auto;
}
.blockTitleWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  cursor: pointer;
}
.blockTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.commentsContentSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
  height: 100%;
  overflow: hidden;
}
.commentsListStyle {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 24px;
  gap: 16px;
  overflow-y: scroll;
}
.commentsListItemStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.commentsListItemProfileWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
}
.commentAvatarStyle {
  width: 25px;
  height: 25px;
  font-size: 12px;
  font-weight: 600;
}
.commentProfileTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.commentTimeStampStyle {
  color: var(--desc-color40);
  font-size: 12px;
  font-weight: 600;
}
.commentTextStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.replyListWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.replyWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  width: fit-content;
}
.replyLinkStyle {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.rotateIcon180Style {
  transform: rotate(180deg);
}
.replyItemStyle {
  display: flex;
  gap: 8px;
}
.replyCurveStyle {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-style: solid;
  border-color: var(--border-color);
  border-width: 0px 0px 1px 1px;
  border-bottom-left-radius: 8px;
}
.dividerStyle {
  width: 100%;
  border: 1px solid var(--border-color);
}
.moreCommentsLinkStyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  cursor: pointer;
}
/* comments end */

@media screen and (max-width: 480px) {
  .cdHeaderBlockStyle {
    height: auto;
    padding: 10px 16px;
  }
  .clusterCreateModalStyle {
    width: 100%;
    height: 90vh;
    align-items: flex-end;
    border-radius: 8px 8px 0 0;
    display: flex;
    position: fixed;
    bottom: 0 !important;
    right: auto !important;
    top: auto !important;
  }
  .membersModalStyle,
  .menuOptionsModalViewStyle,
  .clusterInfoModalStyle {
    width: 100%;
    bottom: 0 !important;
    left: auto !important;
    padding: 0;
    position: fixed;
    right: 0 !important;
    top: auto !important;
    height: fit-content;
    transform: none !important;
    /* z-index: 1; */
  }
  .menuWrapperStyle {
    padding: 10px;
  }
  .deviderStyle {
    display: flex;
    width: 40px;
    height: 4px;
    gap: 0px;
    border-radius: 2px;
    opacity: 0px;
    background: var(--desc-color30);
    align-self: center;
  }
  .cdBodyBlockStyle {
    padding: 16px;
  }
}
