.containerStyle {
  padding: 20px;
  width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.subContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.btnGroupWrapperStyle {
  display: flex;
  gap: 8px;
}
.tabBtnStyle {
  background-color: var(--white);
  color: var(--desc-color40);
  box-shadow: none;
  border: 1px solid var(--border-color);
  padding: 8px;
  border-radius: 4px;
  gap: 4px;
}
.selectedTabBtnStyle {
  background-color: var(--bg-primary-color);
  border-color: transparent;
  color: var(--text-color);
}
.tabBtnIconStyle {
  width: 20px;
  height: 20px;
}
.employeeDataWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: var(--white);
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
}
.formWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: var(--white);
  width: 100%;
  /* border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px; */
}
.formTitleStyle {
  font-family: 'InterTight';
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-color);
}
.inputWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.inputSectionViewStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}
.inputStyle {
  resize: none;
}
.selectTypeInputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.lableTextStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.valueTextStyle {
  color: var(--text-color);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
}
.placeHolderTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color40);
}
.selectTypeContainerStyle {
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
}
.arrowDownIconStyle {
  width: 12px;
  height: 12px;
}
/* popover styles  */
.actionOptionsViewStyle {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 4px;
  width: 288px;
  overflow: scroll;
  max-height: 120px;
  border-bottom: 1px solid var(--border-color);
}
.actionOptionsViewStyle {
  scrollbar-width: auto;
}
.actionOptionsViewStyle::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}
.actionOptionsViewStyle::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  cursor: pointer;
  border-radius: 4px;
}

.actionPopUpViewStyle {
  max-height: 174px;
  padding: 8px;
  background-color: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  border: 1px solid var(--border-color);
}
.optiondTextWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  border-radius: 4px;
}
.optiondTextWrapperStyle:hover {
  background-color: var(--bg-primary-color);
}
.optionsViewStyles {
  width: 100%;

  background: var(--lightGrayishBlue);
  color: var(--textDark);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  cursor: pointer;
}
.customOptionsViewStyles {
  padding: 4px;
  width: 100%;
  border-radius: 4px;
  background: var(--lightGrayishBlue);
  color: var(--primary-color);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  cursor: pointer;
}

.deleteOptionViewStyle {
  color: var(--error-color);
}
.checkIconStyle {
  width: 16px;
  height: 16px;
}
.customTypeContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}
.listIconStyle {
  width: 20px;
  height: 20px;
}
.inputPopUpViewStyle {
  /* top: -5% !important; */
  bottom: unset;
  padding: 8px;
  background-color: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  border: 1px solid var(--border-color);
}
.customEmployeeViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 288px;
  padding: 8px;
}
.overlayStyle {
  z-index: 1;
}
.popUpBtnContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
}
/* Accoun type popover styles */
.optionsSectionStyle {
  max-width: 192px;
  padding: 8px;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  border: 1px solid var(--border-color);
}
.optionsContainerStyle {
  display: flex;
  flex-direction: column;
}
.optionItemWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.optionItemWrapperStyle:hover {
  background-color: var(--light-mint-green-color);
}
.selectedOptionItemWrapperStyle {
  background-color: var(--bg-primary-color);
}
.optionItemTextStyle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}
.dropDownIconStyle {
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
  transition-duration: 1s;
}

/* popover styles  */
.datePickerStyle {
  width: 100%;
  height: 44px;
  border-radius: 8px;
}
.dateIconStyle {
  width: 20px;
  height: 20px;
}
.dateValueStyle {
  color: var(--steel-gray-color);
}
.textAreaStyle {
  height: auto;
}
.categoryWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.labelTextStyle {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
}
.categoryInnerWrapperStyle {
  background: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  width: 100%;
}
.categoryBtnWrapperStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.categoryBtnStyle {
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 8px;
  color: var(--desc-color40);
  box-shadow: none;
}
.selectedCategoryBtnStyle {
  background-color: var(--light-mint-green-color);
  border-color: transparent;
  color: var(--text-color);
}
.verticalLineStyle {
  border-right: 1px solid var(--border-color);
  height: 100%;
}
.categroyCloseIconStyle {
  width: 15px;
  height: 15px;
}
.newCategoryInputWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.addCategoryBtnViewStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.categoryBtnDarkStyle {
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 8px;
  color: var(--text-color);
  box-shadow: none;
}
.submitBtnWrapperStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.btnsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.errorTextStyle {
  color: var(--error-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.modalViewStyle {
  width: 470px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
/* alert modal styles  */
.alertModalViewStyle {
  width: 440px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
  z-index: 2;
}
.alertModalContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.modalDataWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.detailsWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.barStyle {
  width: 4px;
  max-height: 100%;
  border-radius: 2px;
  background-color: var(--border-color);
}
.listWrapperStyle {
  display: flex;
  flex-direction: column;
}
.textLabelStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color60);
}
.labelDetailsTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.alertHeaderTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--text-color);
}
.alertBtnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

/* pagination  */
.paginationWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
}
.stepsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.stepsTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: var(--text-color);
}
.stepActiveTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: var(--desc-color60);
}
.stepStatusActiveBarStyle {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: var(--primary-color);
}
.stepStatusBarStyle {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: var(--bg-primary-color);
}
/* remainders styles  */

.userInfoWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.labelWrapperStyle {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.viewAllWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.viewAllSubWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.addNewReminderWrapperStyle {
  background-color: var(--purple-color10);
  color: var(--deep-purple-color);
}
.viewAllTextStyle {
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--deep-purple-color);
}
.viewAllIconStyle {
  width: 16px;
  height: 16px;
}
.remaindersContainerStyle {
  max-width: 100%;
}
.reminderModalViewStyle {
  position: fixed;
  top: 60px;
  left: unset;
  right: 20px;
  width: 568px;
  border-radius: 4px;
}

@media screen and (max-width: 480px) {
  .containerStyle {
    padding: 16px;
    gap: 20px;
  }
  .inputSectionViewStyle {
    display: flex;
    flex-direction: column;
  }
  .newCategoryInputWrapperStyle {
    flex-direction: column;
  }
  .addCategoryBtnViewStyle {
    justify-content: flex-end;
  }
  .alertModalViewStyle {
    width: 100%;
    align-items: flex-end;
    border-radius: 8px 8px 0 0;
    display: flex;
    position: fixed;
    bottom: 0 !important;
    right: auto !important;
    left: 0;
    top: auto !important;
    transform: none;
  }
  .reminderModalViewStyle {
    width: 100%;
    height: auto !important;
    border-radius: 8px 8px 0 0;
    display: flex;
    position: fixed;
    bottom: 0 !important;
    right: auto !important;
    /* top: auto !important; */
  }
  .employeeDataWrapperStyle {
    padding: 12px;
  }
}
