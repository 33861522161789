.remainderOverViewStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.overViewHeaderStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  width: 100%;
  gap: 8px;
}
.overViewHeaderTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: var(--text-color);
}
.arrowBackIconStyle {
  width: 20px;
  height: 20px;
}
.backTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}
.remainderContentViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;
}
.customContainerStyle,
.customContainerStyle:last-child {
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: none;
}
.reminderDetailsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}
.curvedViewStyle {
  min-width: 12px;
  height: 12px;
  border-radius: 0px 0px 0px 8px;
  border-bottom: 1px solid var(--medium-gray-color);
  border-left: 1px solid var(--medium-gray-color);
  background: var(--white);
  margin-left: 8px;
}
.reminderDetailsSubViewStyle {
  display: flex;
  flex-direction: column;
  width: 94%;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}
.reminderDetailsHeaderStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--bg-secondary-color);
  border-bottom: 1px solid var(--border-color);
}
.reminderDetailsHeaderTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
  word-wrap: break-word;
}
.reminderDetailsDescTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--desc-color60);
  width: 100%;
  padding: 8px 12px;
  word-wrap: break-word;
}
.attachmentsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.attachmentsTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}
.customattachContainerStyle {
  width: 100%;
}
.dividerStyle {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
}
.dismissBtnStyle {
  border: 1px solid var(--deep-purple-color);
  color: var(--deep-purple-color);
  align-self: flex-end;
}
