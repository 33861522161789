.containerStyle {
  padding: 20px;
  width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.formWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: var(--white);
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 24px;
}
.inputWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.btnsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.modalViewStyle {
  width: 470px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.modalCardBtnStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.btnGroupStyle:not(:only-child) {
  /* justify-content: space-between; */
}
.btnGroupStyle:has(:only-child) {
  justify-content: flex-end;
}
.errorTextStyle {
  font-family: 'InterTight';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--error-color);
  text-align: right;
}
@media screen and (max-width: 480px) {
  .containerStyle {
    padding: 16px;
    gap: 20px;
  }
  .formWrapperStyle {
    padding: 16px;
  }
  .modalViewStyle {
    width: 100%;
    align-items: flex-end;
    border-radius: 8px 8px 0 0;
    display: flex;
    position: fixed;
    bottom: 0 !important;
    right: auto !important;
    left: 0;
    top: auto !important;
    transform: none;
  }
}
