.inputContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inputSubContainerStyle {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  border: 1px solid var(--border-color);
  align-items: center;
  padding: 0px 0px 0px 16px;
  border-radius: 8px;
}
.inputStyle {
  width: 100%;
  height: 44px;
  background: var(--white);
  border: 0.1px solid var(--border-color);
  border-radius: 8px;
  border-left: none;
  font-family: 'Manrope', sans-serif;
  color: var(--textDark);
  font-weight: 450;
  font-size: 14px;
  /* line-height: 20px; */
}
.inputStyle::placeholder {
  color: var(--steel-gray-color);
}
.inputStyle:active,
.inputStyle:focus {
  outline: none;
}
.inputStyle::-webkit-outer-spin-button,
.inputStyle::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.inputStyle[type='number'] {
  -moz-appearance: textfield;
}
.errorTextStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--error-color);
}
.lableTextStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
}
.deviderStyle {
  height: 20px;
  border: 1px solid var(--border-color);
}
.countryCodeStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--textDark);
  cursor: pointer;
}
.countryCodeModalStyle {
  max-height: 200px;
  width: fit-content;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
}
.countryCodeItemStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
  padding: 8px 16px;
  cursor: pointer;
}
