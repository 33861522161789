.containerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
}
.topSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.detailsViewStyle {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.avatarViewStyle {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  padding: 10px;
  background-color: var(--bg-primary-color);
}
.titleViewStyle {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.titleTextViewStyle {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subTitleTextViewStyle {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
}
.descTextStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--desc-color60);
}
.bottomSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.bottomSubSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.bottomLeftSubSectionStyle {
  display: flex;
  flex-direction: column;
}
.bottomLeftlabelStyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--desc-color40);
}
.assignedTextColor {
  color: var(--primary-color);
}
.unAssignedTextColor {
  color: var(--desc-color40);
}
.markAsLostTextStyle {
  color: var(--error-color);
}
.btnStyle {
  position: relative !important;
  background-color: var(--blue-ribbon-color);
  color: var(--primary-color);
  border-radius: 4px;
  box-shadow: none;
}
.deleteIconStyle {
  cursor: pointer;
}
