.containerStyle {
  display: flex;
  align-items: center;
  gap: 16px;
}
.infoWrapperStyle {
}
.infoSubWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.pointerStyle {
  cursor: pointer;
}
.titleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.descStyle {
  color: var(--desc-color80);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.capsuleViewStyle {
  border-radius: 4px;
  border: 1px solid var(--border-color);
  box-shadow: 0px 4px 16px 0px var(--pale-mint-green-color);
  padding: 2px 5px;
}
.labelStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--text-color);
}
