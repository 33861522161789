.statisticCardWrapperStyle {
  width: 216.67px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 2px;
  background: var(--bg-secondary-color);
  border-radius: 8px;
}
.statisticCardWrapperStyle:hover {
  background-color: var(--light-mint-green-color);
}
.statsCountStyle {
  font-family: 'InterTight', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: var(--text-color);
  align-self: stretch;
}
.statsTitleStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--desc-color80);
  align-self: stretch;
}
.cursorStyle {
  cursor: pointer;
}
/* Media Query Start */

@media screen and (max-width: 480px) {
  .statisticCardWrapperStyle {
    width: 100%;
    padding: 12px 16px;
    height: auto;
  }
  .statsTitleStyle {
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
  }
}
/* Media Query End */
