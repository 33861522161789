.clusterInfoViewStyle {
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.clusterInfoHeaderViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--medium-gray-color);
}
.clusterInfoHeaderTextViewStyle {
  display: flex;
  flex-direction: column;
}
.headerTextStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.headerDescTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  color: var(--desc-color60);
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.clusterInfoHeaderRightViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.closeIconStyle {
  cursor: pointer;
  right: 12px;
  top: 12px;
  min-width: 20px;
  min-height: 20px;
}
.bodyViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}
.assetsSectionViewStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.labelTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--desc-color60);
  letter-spacing: 0em;
  text-align: left;
}
.assestsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}
.capsuleViewStyle,
.userCapsuleViewStyle,
.maintenanceCapsuleViewStyle,
.startDateCapsuleViewStyle {
  width: fit-content;
  padding: 0px, 4px, 0px, 4px;
  border-radius: 4px;
  border: 1px;
  gap: 4px;
}
.capsuleTitleStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.capsuleViewStyle {
  background: linear-gradient(0deg, var(--desc-color05), var(--desc-color05)),
    linear-gradient(0deg, var(--lavender-color), var(--lavender-color));
}
.userCapsuleViewStyle {
  background: linear-gradient(0deg, var(--desc-color05), var(--desc-color05)),
    linear-gradient(0deg, var(--light-mint-green-color), var(--light-mint-green-color));
}
.assestsMaintanenceViewStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.maintenanceCapsuleViewStyle {
  background: linear-gradient(0deg, var(--desc-color05), var(--desc-color05)),
    linear-gradient(0deg, var(--bg-primary-color), var(--bg-primary-color));
}
.startDateViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.startDateCapsuleViewStyle {
  background: linear-gradient(0deg, var(--desc-color05), var(--desc-color05)),
    linear-gradient(0deg, var(--bg-secondary-color), var(--bg-secondary-color));
}
