.containerStyle {
  width: 100%;
  height: 100%;
  background: var(--white);
  display: flex;
  flex-direction: row;
}
.listSectionWrapperStyle {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--border-color);
  max-width: 360px;
  min-width: 360px;
  overflow-y: scroll;
}
.headerSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 20px 8px 20px;
  background-color: var(--white);
  position: sticky;
  top: 0;
}
.searchWrapperStyle {
  display: flex;
  gap: 8px;
  background-color: var(--white);
}
.searchInputStyle {
  background-color: var(--light-gray-color);
}
.searchBtnStyle {
  padding: 8px 16px;
  gap: 8px;
  border: 1px solid var(--border-color);
}
.searchIconStyle {
  width: 20px;
  height: 20px;
}
.filterSectionWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.headerTextStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
/* filter wrapper stylees */
.selectBtnStyle {
  padding: 4px 8px;
  box-shadow: none;
  border-radius: 4px;
}
/* end */
/* list section */
.listWrapperStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 8px;
}
.emptyViewStyle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emptyViewTextStyle {
  color: var(--desc-color60);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  width: 60%;
}
/* chat overview style start ----*/
.chatOverViewSectionStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
}
@media screen and (max-width: 480px) {
  .listSectionWrapperStyle {
    max-width: 100%;
    max-height: 100%;
  }
}
