.wrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.labelTextStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.pikcerContainerStyle {
  min-width: 140px;
  width: fit-content;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--white);
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}
.disabledStyle {
  opacity: 0.8;
  pointer-events: none;
}
.labelStyle {
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  text-wrap: nowrap;
}
.valueStyle {
  color: var(--text-color);
}
.placeHolderStyle {
  color: var(--desc-color40);
}
.iconWrapperStyle {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconRotateStyle {
  transform: rotate(180deg);
}
.iconWrapperStyle img {
  width: 100%;
  height: 100%;
}
.pickerWrapperStyle {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
}
.overlayStyle {
  z-index: 1;
}
