.containerStyle {
  display: flex;
  gap: 8px;
  position: relative;
  align-items: baseline;
}
.containerStyle:not(:last-child)::after {
  border-left: 1px solid var(--border-color);
  content: '';
  height: 100%;
  left: 5.5px;
  position: absolute;
  top: 16px;
}
.containerStyle:last-child {
  margin-bottom: 16px;
}
.dotStyle {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  background-color: var(--orange-color);
  border: 2px solid var(--white);
  border-radius: 50%;
}
.contentBlockStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.contentSubBlockStyle {
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}
.pointerStyle {
  cursor: pointer;
}
.basicInfoBlockStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.titleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.checkedBlockStyle {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;
  width: 100%;
}
.descStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  white-space: nowrap;
}
.trackStyle {
  max-width: 160px;
}
.barIncompleteStyle {
  background-color: var(--amber-orange-color);
}
.subTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.subDescStyle {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--desc-color40);
  font-family: 'InterTight', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.complaintBlockStyle {
  display: flex;
  gap: 2px;
  align-items: center;
}
.complaintCountTextStyle {
  padding: 0 4px;
  border-radius: 4px;
}
.complaintResolvedCountStyle {
  background-color: var(--soft-verdant-mint-color);
  border: 1px solid var(--soft-verdant-mint-color);
}
.complaintRaisedCountStyle {
  background-color: var(--light-pink);
  border: 1px solid var(--border-light-pink-shade);
}
.reportIconStyle {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  position: relative;
}
.contentBottomBlockStyle {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-secondary-color);
  border-radius: 8px;
  gap: 12px;
  padding: 16px 0;
}
.filterBlockStyle {
  display: flex;
  gap: 4px;
  padding: 0 16px;
}
.selectedFilterTabStyle {
  background-color: var(--bg-primary-color);
}
.selectedFilterTabStyle span {
  color: var(--text-color);
}
.listStyle {
  display: flex;
  flex-direction: column;
}
.listItemStyle {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--border-color);
  cursor: pointer;
  padding: 4px 16px;
}
.listItemContentStyle {
  width: 100%;
}
.listItemTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listItemDescStyle {
  color: var(--desc-color60);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dotRedStyle {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: var(--error-color);
  border: 1px solid var(--white);
}
.dotGreenStyle {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--dark-green-color);
  border: 1px solid var(--white);
}
.dotYellowStyle {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--amber-orange-color);
}
.dotMixedColorContainerStyle {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid var(--white);
}
.dotMixedRedStyle {
  width: 8px;
  height: 4px;
  flex-shrink: 0;
  background-color: var(--error-color);
}
.dotMixedYellowStyle {
  width: 8px;
  height: 4px;
  flex-shrink: 0;
  background: var(--amber-orange-color);
}
.dotMixedGreenStyle {
  width: 8px;
  height: 4px;
  flex-shrink: 0;
  background: var(--dark-green-color);
}
.assetReportModalStyle {
  left: auto;
  max-height: calc(100vh - var(--header-height) * 2);
  overflow: hidden;
  position: absolute;
  right: 2%;
  top: var(--header-height);
  width: 626px;
}
/* verify modal styles */
.assetVerifyModalStyle {
  left: auto;
  max-height: calc(100vh - var(--header-height) * 2);
  overflow: hidden;
  position: absolute;
  right: 2%;
  top: var(--header-height);
  width: 568px;
}
.verifyModalContainerStyle {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.verifyModalHeaderStyle {
  display: flex;
  flex-direction: row;
  padding: 16px 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}
.verifyHeaderTextStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.verifyModalMiddleViewStyle {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  height: 85%;
  justify-content: space-between;
}
.verifyModalInputContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.verifyNoteTextStyle {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.verifyNoteViewStyle {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--bg-secondary-color);
}
.verifyBtnsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}

/* scanner styles  */

.scannerSectionStyle {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
}

.qrReader {
  width: 100% !important;
  height: 100% !important;
  margin: auto;
}
.qrReader section {
  height: 100% !important;
}
.qrReader section div {
  /* box-shadow: var(--white) 0px 0px 0px 1px inset !important;
  border-top-width: 300px !important;
  border-bottom-width: 300px !important;
  border-left-width: 75px !important;
  border-right-width: 75px !important;
  background: var(--darkBlueOpacity1); */
  box-shadow: none !important;
  /* background: var(--darkBlueOpacity1); */
  border-width: 0px !important;
  top: 50% !important;
  left: 50% !important;
  width: 240px !important;
  height: 240px !important;
  transform: translate(-50%, -50%);
}
.scanBarStyle {
  width: 250px;
  height: 250px;
  outline-offset: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.scanBarStyle span::before {
  content: '';
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 10%;
  width: 80%;
  height: 5px;
  border-radius: 2px;
  background: var(--white);
  box-shadow: 0 0 48px 10px var(--white);
  clip-path: inset(0);
  animation: x 1s ease-in-out infinite alternate, y 2s ease-in-out infinite;
}

.scanBarStyle:before,
.scanBarStyle:after,
.scanBarStyle em:after,
.scanBarStyle em:before {
  border-color: var(--white);
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border-style: solid;
  border-width: 0px;
}

.scanBarStyle:before {
  left: 0;
  top: 0;
  border-left-width: 5px;
  border-top-width: 5px;
  border-radius: 20px 0 0 0;
}

.scanBarStyle:after {
  right: 0;
  top: 0;
  border-right-width: 5px;
  border-top-width: 5px;
  border-radius: 0 20px 0 0;
}
.scanBarStyle em:before {
  left: 0;
  bottom: 0;
  border-left-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 0 20px;
}
.scanBarStyle em:after {
  right: 0;
  bottom: 0;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 20px 0;
}

@keyframes x {
  to {
    transform: translateY(-100%);
    top: 90%;
  }
}

@keyframes y {
  0% {
    clip-path: inset(0 0 0 0);
  }
  49% {
    clip-path: inset(-100px 0 0 0);
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 -100px 0);
  }
}
.scannerOptionsViewStyle {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0px;
  width: 100%;
}
.scannerOptionsSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px;
}
.scannerIconsViewStyle {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 20px;
  background: rgba(30, 30, 32, 1);
  box-shadow: 0px 48px 96px 0px rgba(30, 30, 32, 0.08);
  z-index: 1;
}
.scannerBottomOptionsViewStyle {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.scannerBottomOptionsSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 20px;
}
.scannerBtnViewStyle {
  background: rgba(30, 30, 32, 1);
  box-shadow: 0px 48px 96px 0px rgba(30, 30, 32, 0.16);
  padding: 12px, 16px, 12px, 16px;
  border-radius: 24px;
  color: var(--white);
  z-index: 1;
}

@media screen and (max-width: 480px) {
  .assetReportModalStyle,
  .assetVerifyModalStyle {
    bottom: 0 !important;
    left: 0 !important;
    position: fixed;
    right: 0 !important;
    top: auto !important;
    width: 100%;
  }
}
