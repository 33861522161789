.scannerSectionStyle {
  max-width: 500px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
}

.qrReaderStyle {
  width: 100%;
  height: 100% !important;
  margin: auto;
}
.qrReaderStyle section {
  padding: 0px !important;
  height: 100% !important;
}
.qrReaderStyle section div {
  box-shadow: none !important;
  /* background: var(--darkBlueOpacity1); */
  border-width: 0px !important;
  top: 50% !important;
  left: 50% !important;
  width: 240px !important;
  height: 240px !important;
  transform: translate(-50%, -50%);
}
.scannerOptionsViewStyle {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0px;
  width: 100%;
}
.scannerOptionsSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px;
}
.scannerIconsViewStyle {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 20px;
  background: var(--text-color);
  box-shadow: 0px 48px 96px 0px rgba(30, 30, 32, 0.08);
  z-index: 1;
}
.scannerBottomOptionsViewStyle {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.scannerBottomOptionsSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 20px;
}
.scannerBtnViewStyle {
  background: var(--text-color);
  box-shadow: 0px 48px 96px 0px rgba(30, 30, 32, 0.16);
  padding: 12px, 16px, 12px, 16px;
  border-radius: 24px;
  color: var(--white);
  z-index: 1;
}

.scanBarStyle {
  width: 250px;
  height: 250px;
  outline-offset: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.scanBarStyle span::before {
  content: '';
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 10%;
  width: 80%;
  height: 5px;
  border-radius: 2px;
  background: var(--white);
  box-shadow: 0 0 48px 10px var(--white);
  clip-path: inset(0);
  animation: x 1s ease-in-out infinite alternate, y 2s ease-in-out infinite;
}

.scanBarStyle:before,
.scanBarStyle:after,
.scanBarStyle em:after,
.scanBarStyle em:before {
  border-color: var(--white);
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border-style: solid;
  border-width: 0px;
}

.scanBarStyle:before {
  left: 0;
  top: 0;
  border-left-width: 5px;
  border-top-width: 5px;
  border-radius: 20px 0 0 0;
}

.scanBarStyle:after {
  right: 0;
  top: 0;
  border-right-width: 5px;
  border-top-width: 5px;
  border-radius: 0 20px 0 0;
}
.scanBarStyle em:before {
  left: 0;
  bottom: 0;
  border-left-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 0 20px;
}
.scanBarStyle em:after {
  right: 0;
  bottom: 0;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 20px 0;
}

@keyframes x {
  to {
    transform: translateY(-100%);
    top: 90%;
  }
}

@keyframes y {
  0% {
    clip-path: inset(0 0 0 0);
  }
  49% {
    clip-path: inset(-100px 0 0 0);
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 -100px 0);
  }
}
.modalCustomStyle {
  width: 470px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.verifyBlockViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
}
.topSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.headerViewStyle {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid var(--desc-color80);
}
.bottomSectionStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px 20px;
}
.headerTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--textDark);
}
.inputSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 20px;
}
.messageTextViewStyle {
  width: 100%;
  background: var(--bg-secondary-color);
  padding: 8px 12px;
  border-radius: 4px;
}
.messageTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  color: var(--desc-color60);
}
.lableTextStyle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
}
.inputStyle {
  position: unset;
}
.closeIconStyle {
  cursor: pointer;
}
.btnViewStyle {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .modalCustomStyle {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .verifyBlockViewStyle {
    top: 66px;
    border-radius: 8px 8px 0px 0px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .inputSectionStyle,
  .bottomSectionStyle {
    padding: 0px;
  }
}
