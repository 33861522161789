.containerStyle {
  width: 100%;
  padding: 20px;
  gap: 28px;
  display: flex;
  flex-direction: column;
}
.formWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--white);
}
.formTitleStyle {
  font-family: 'InterTight', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-color);
}
.inputsWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.inputLabelStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}
.inputWrapperStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px 16px;
  position: relative;
}
.pointerStyle {
  cursor: pointer;
}
.textAreaStyle {
  resize: none;
}
.assetPlaceHolderStyle {
  color: var(--steel-gray-color);
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
}
.assetSelectDropDownIconStyle {
  width: 12px;
  height: 12px;
}
.assetPopUpStyle {
  border-radius: 8px;
  background-color: var(--white);
  max-width: 568px;
  width: 100%;
  right: 0px;
  top: 100%;
  border: 1px solid var(--border-color);
  box-shadow: 0 48px 96px 0 var(--shadow-color08);
}
.assetPopUpHeaderStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: 20px;
}
.assetPopUpTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.assetPopUpHeaderIconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.assetPopUpBodyStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.assetOptionWrapperStyle {
  display: flex;
  align-items: center;
  gap: 16px;
}
.selectedOptionLabelStyle {
  color: var(--text-color);
}
.assetListWithInputStyle {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.assetListInputStyle {
  border: none;
}
.avatarStyle {
  background-color: var(--bg-primary-color);
  padding: 8px;
}
.closeIconStyle {
  width: 20px;
  height: 20px;
}
.assetListWrapperStyle {
  width: 100%;
  max-height: 235px;
  overflow-y: scroll;
}
.assetListStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  cursor: pointer;
}
.assetListStyle:hover {
  background-color: var(--light-mint-green-color);
}
.assetListImgStyle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: contain;
}
.assetListTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.assetListDescStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-weight: 450;
  line-height: 16px;
}
.emptyDataStyle {
  color: var(--desc-color60);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border-top: 1px solid var(--border-color);
}
.doneBtnStyle {
  margin-left: auto;
}
.bottomBlockStyle {
  display: flex;
  justify-content: space-between;
}
.btnWrapperStyle {
  display: flex;
  gap: 8px;
}
.successModalStyle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 468px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.modalTopInfoWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.modalTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}
.modalTitleLinkStyle {
  color: var(--primary-color);
}
.modalDescStyle {
  color: var(--desc-color80);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.timeLineContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.timeLineItemWrapperStyle {
  display: flex;
  gap: 16px;
  position: relative;
}
/* .timeLineItemWrapperStyle:not(:first-child)::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 9.5px;
  height: 50%;
  border-left: 1px dashed var(--medium-gray-color);
} */
.timeLineItemWrapperStyle:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 25px;
  left: 9.5px;
  height: calc(100% - 20px);
  border-left: 1px dashed var(--medium-gray-color);
}
.dotStyle {
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--medium-gray-color);
}
.contentStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.timeLineItemTitleStyle {
  color: var(--desc-color40);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.contentBlockStyle {
  display: flex;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
}
.contentImgStyle {
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
}
.contentImgStyle img {
  object-fit: contain;
}
.contentTitleStyle {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contentDescStyle {
  font-size: 14px;
  line-height: 20px;
  color: var(--desc-color60);
  font-weight: 450;
}
.modalBtnBlockStyle {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.errorTextStyle {
  color: var(--error-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
@media screen and (max-width: 480px) {
  .containerStyle {
    padding: 16px;
    gap: 20px;
  }
  .formWrapperStyle {
    padding: 16px;
  }
  .successModalStyle {
    width: 100%;
    border-radius: 8px 8px 0 0;
    display: flex;
    position: fixed;
    bottom: 0 !important;
    right: auto !important;
    left: 0;
    top: auto !important;
    transform: none;
  }
}
