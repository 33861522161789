.containerStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  max-width: 270px;
  min-width: 270px;
  width: 100%;
  position: relative;
}
.headerWarpperStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.headerContentStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.headerTitleStyle {
  color: var(--text-color);
  font-family: 'InterTight';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}
.iconWrapperStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
}
.iconWrapperStyle:hover {
  background-color: var(--bg-primary-color);
  transition: all 0.4s ease-in-out;
}
.iconStyle {
  width: 60%;
  height: 60%;
}
.weeksWrapperStyle {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  gap: 2px;
}
.weekTitleStyle {
  display: flex;
  width: 100%;
  height: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--desc-color60);
  text-align: center;
  font-family: 'InterTight';
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.daysWrapperStyle {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  gap: 2px;
}
.dateWrapperStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  cursor: pointer;
  border-radius: 10px;
}
.dateWrapperStyle:not(:empty, .selectedDateWrapperStyle):hover {
  background-color: var(--bg-primary-color);
}
.currentDateWrapperStyle {
  background-color: var(--bg-primary-color);
}
.selectedDateWrapperStyle,
.selectedDateWrapperStyle:hover {
  background-color: var(--primary-color);
}
.dateWrapperStyle:empty {
  cursor: initial;
}
.disabledDateWrapperStyle {
  pointer-events: none;
  opacity: 0.5;
}
.dateLabelStyle {
  color: var(--text-color);
  text-align: center;
  font-family: 'InterTight';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.selectedDateLabelStyle {
  color: var(--white);
}
.circleStyle {
  width: 24px;
  height: 24px;
  border: 4px solid var(--border-color);
  background-color: var(--white);
  border-radius: 50%;
}
.monthWrapperStyle {
  display: grid;
  padding: 16px;
  background-color: var(--white);
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  place-items: center;
}
.monthStyle {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  font-family: 'InterTight';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: fit-content;
}
