.eachWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 100%;
  padding: 12px 0px;
  border-bottom: 1px solid rgba(225, 226, 228, 1);
}
.eachWrapperStyle:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 100%;
  border-bottom: none;
  padding: 12px 0px;
}
.infoViewStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  width: 47%;
  border-right: 1px solid rgba(225, 226, 228, 1);
}
.infoCustomStyle {
  border-right: none;
  width: 100% !important;
}
.infoViewStyle:last-child {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  width: 47%;
  border-right: none;
}
.lableStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  color: var(--desc-color40);
}
.textStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--text-color);
  word-break: break-all;
}
.deviderStyle {
  width: 1px;
  height: 100%;
  background-color: rgba(225, 226, 228, 1);
}
