.wrapperStyle {
  background: var(--bg-primary-color);
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.cardInfoStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.titleStyle {
  font-family: 'InterTight';
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: var(--text-color);
}
.descStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--desc-color80);
}

@media screen and (max-width: 480px) {
  .wrapperStyle {
    gap: 20px;
    padding: 16px;
  }
  .descStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
