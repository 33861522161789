.containerWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.lableTextStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.inviteUserWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
  background: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  /* padding: 12px 16px; */
}
.assignUserInputWrapperStyle {
  position: relative;
  width: 100%;
}
.assignUserInputStyle {
  border: none;
}
.dropDownWrapperStyle {
  max-width: 480px;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
}
.profileWrapperStyle,
.selectedProfileWrapperStyle {
  display: flex;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
}
.assignedUserRemoveIconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.profileWrapperStyle:hover {
  background-color: var(--bg-secondary-color);
}
.profileTextWrapperStyle {
  display: flex;
  flex-direction: column;
}
.profileTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.profileDescStyle {
  color: var(--desc-color80);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.selectedUserWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
}
.noDataStyle {
  padding: 12px;
  color: var(--desc-color80);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.capsuleViewStyle {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--light-mint-green-color);
}
