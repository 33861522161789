.mainContainerStyle {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* padding: 0px 20px; */
  background-color: var(--white);
}
.headerViewStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px;
  border-bottom: 1px solid var(--border-color);
}
.closeIMgViewStyle {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgStyle {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: cover;
}
.assetDetailsViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 20px;
  border-bottom: 1px solid var(--border-color);
}
.assetImagesViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.userImgViewStyle {
  width: 48px;
  height: 48px;
  border-radius: 36px;
  border: 1px solid var(--white);
}
.userImgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.assetImgViewStyle {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 1px solid var(--white);
  background-color: var(--bg-secondary-color);
  margin-left: -15px;
}
.assetDetailsSubViewStyle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3px;
}
.titleTextStyle {
  color: var(--black);
  font-family: 'InterTight';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.assetNameViewStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0px;
  border-radius: 4px;
  background-color: var(----bg-primary-color);
  width: fit-content;
}
.assetNameTextStyle {
  color: var(--black);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.uniqIdTextStyle,
.textStyle {
  color: var(--desc-color40);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.textStyle {
  color: var(--desc-color80);
}
.optionsViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 20px;
  border-bottom: 1px solid #e8e8e8;
}
.eachOptionViewStyle,
.leftSectionViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.leftSectionViewStyle {
  justify-content: flex-start;
  gap: 8px;
}
.optionImgViewStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 24px;
  background-color: var(--pale-pink-color);
}
.optionImgStyle {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.primaryColorStyle {
  background-color: var(--pale-pink-color);
}
.optionDetailsViewStyle {
  display: flex;
  flex-direction: column;
}
.optionTitleTextStyle {
  overflow: hidden;
  color: var(--black);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.optionDescTextStyle {
  color: var(--desc-color60);
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.optionRightImgViewStyle {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.optionRightImgStyle {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: contain;
}
.EmptyDataViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
