.remainderItemWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--shadow-color16);
}
.remainderItemWrapperStyle:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: none;
}
.remainderItemLeftWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.iconCheckContainerStyle {
  min-width: 20px;
  height: 20px;
  overflow: hidden;
}
.iconOptionsContainerStyle {
  position: relative;
  min-width: 20px;
  height: 20px;
  cursor: pointer;
}
.remainderTextWrapperStyle {
  display: flex;
  flex-direction: column;
}
.remainderTitleTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.remainderTimeTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: var(--desc-color60);
}
.remainderTimeTextStyle span {
  color: var(--deep-purple-color);
  font-weight: 600;
}
