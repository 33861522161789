.containerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  width: 100%;
  position: relative;
}
.subContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  position: relative;
}
.assetStyles {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--white);
  box-shadow: 0px 4px 16px 0px var(--shadow-color08);
  width: fit-content;
}
.assetTextStyles {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.titleViewStyle,
.currentlyAssignedViewStyles,
.assignToViewStyles {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 420px;
}
.titleTextStyles {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.descpTextStyles {
  color: var(--desc-color80);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.currentlyAssignedlabelStyles,
.assignToLabelTextStyles {
  color: var(--desc-color60);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.userDetailsStyles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  background-color: var(--bg-secondary-color);
  border-radius: 4px;
  padding: 12px 16px;
}
.avatarInfoCardStyle {
  gap: 8px;
}
.accoutBtnStyles {
  padding: 8px;
  background-color: var(--light-mint-green-color);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  cursor: auto;
}
.inviteUserWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
  background: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  /* padding: 12px 16px; */
}
.assignUserInputWrapperStyle {
  position: relative;
  width: 100%;
}
.assignUserInputStyle {
  border: none;
}
.dropDownWrapperStyle {
  max-width: 400px;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
}
.profileWrapperStyle,
.selectedProfileWrapperStyle {
  display: flex;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
}
.assignedUserRemoveIconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.profileWrapperStyle:hover {
  background-color: var(--bg-secondary-color);
}
.profileTextWrapperStyle {
  display: flex;
  flex-direction: column;
}
.profileTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.profileDescStyle {
  color: var(--desc-color80);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.selectedUserWrapperStyle {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.noDataStyle {
  padding: 12px;
  color: var(--desc-color80);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.addUserBtnStyle {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}
.btnsWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}

@media screen and (max-width: 480px) {
  .containerStyle {
    padding: 20px 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .subContainerStyle {
    height: 100%;
  }
  .btnsWrapperStyle {
    justify-content: space-between;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .btnStyle {
    width: 100%;
  }
}
