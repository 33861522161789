.listContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.headerWrapperStyle {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 20px 20px 10px 20px;
  background-color: var(--white);
  position: sticky;
  top: 0px;
}
.filterWrapperStyle {
  display: flex;
  width: 100%;
  gap: 16px;
  background-color: var(--white);
}
.filterInputStyle {
  background-color: var(--light-gray-color);
  border: none;
  height: 36px;
  border-radius: 4px;
  padding: 8px 12px;
  padding-left: 36px !important;
}
.filterBtnStyle {
  padding: 8px 16px;
  gap: 8px;
}
.filterIconStyle {
  width: 20px;
  height: 20px;
  left: 22px;
}
.dotViewStyle {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}

/* list section start */
.tableSectionStyle {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
}
.tableWrapperStyle {
  padding: 0;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  border-collapse: collapse;
  vertical-align: middle;
  width: 100%;
}

.tableHeaderRowStyle th {
  padding: 0px 16px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(30, 30, 32, 0.4);
  background: #ffffff;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  text-align: left;
}
.tableHeaderRowStyle th:first-child,
.tableRowStyle td:first-child {
  padding-left: 0px;
}
.tableHeaderRowStyle th:last-child {
  border-right: none;
}
.tableRowStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
}
.tableRowStyle td {
  padding: 10px 16px;
}
.customColStyle {
  display: flex;
  flex-direction: column;
}
.avatarWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.avatarStyle {
  width: 24px;
  height: 24px;
  font-size: 14px;
}
.tableSmallTextStyle {
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
}
.assignedStyle {
  color: var(--primary-color);
}
.lostStyle {
  color: var(--error-color);
}
.unAssignedStyle {
  color: var(--desc-color60);
}
.emptyDataStyle {
  color: var(--desc-color60);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 16px;
  padding: 10px;
}
.statusViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.assignedBgStyle {
  background-color: var(--primary-color);
}
.unAssignedBgStyle {
  background-color: var(--desc-color60);
}
.markLostBgStyle {
  background-color: var(--error-color);
}
/* list section end */
.mobileViewStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
}
.cardStyle {
  cursor: pointer;
}
.emptyViewStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
