.containerStyle {
  width: 100%;
  max-height: calc(100vh - 2 * var(--header-height));
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  gap: 32px;
}
.headerBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.headerTopWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-color);
}
.headerIconsWrapperStyle {
  display: flex;
  gap: 8px;
  align-items: center;
}
.headerIconStyle {
  width: 20px;
  height: 20px;
}
.headerBottomWrapperStyle {
  display: flex;
  align-items: flex-end;
  gap: 4px;
}
.stepItemStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
}
.subTitleStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--text-color);
}
.subTitleGrayStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--desc-color60);
}
.bodyBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow-y: scroll;
}
.labelTextStyle {
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
  font-weight: 600;
}
.assetTipViewStyle {
  padding: 8px 12px;
  border-radius: 4px;
  background-color: var(--peach-color);
}
.assetTipDescStyle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--desc-color80);
}
.linkStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--primary-color);
  text-decoration: none;
}
.similarAssetViewStyle {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: var(--bg-primary-color);
}
.similarAssetLeftWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.similarAssetTitleStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: var(--text-color);
}
.similarAssetDescStyle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--desc-color60);
}
.assetListStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.activeCapsuleStyle {
  background-color: var(--light-mint-green-color);
  border-color: transparent;
}
.assetItemBlcokStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.assetItemLabelWrapperStyle {
  display: flex;
  align-items: center;
  gap: 2px;
}
.assetItemLabelStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--desc-color60);
}
.assetItemLabelIconStyle {
  width: 16px;
  height: 16px;
  position: relative;
}
.assetItemBottomWrapperStyle {
  display: flex;
  gap: 4px;
  justify-content: flex-end;
}
.assetItemTemplateBtnStyle {
  padding: 2px 4px;
  gap: 2px;
  background-color: var(--bg-secondary-color);
  border: 1px solid var(--light-shade-gray-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
}
.toolTipModalStyle {
  top: -16px !important;
  max-width: 256px;
  width: 100%;
  padding: 12px;
}
.zIndexOverlayStyle {
  z-index: 1;
}
.checklistCreateModalStyle {
  top: -38px !important;
  width: 320px;
  border: 1px solid var(--border-color);
}
.checklistCreateBlockStyle {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.checklistCreateBtnWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.checklistBlcokStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.checklistEachWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 4px;
  background-color: var(--bg-secondary-color);
}
.checklistHeaderStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checklistTitleStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--text-color);
}
.checklistMenuModalStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  border-radius: 4px;
  width: 159px;
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  top: -12px !important;
}
.checklistMenuItemTitleStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--text-color);
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}
.checklistMenuItemTitleStyle:hover {
  background-color: var(--bg-primary-color);
}
.checklistEditModalStyle {
  width: 320px;
  top: -36px !important;
}
.checklistDeleteModalStyle {
  top: -36px !important;
  width: 320px;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
}
.deleteChecklistTitleStyle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}
.deleteChecklistDescStyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--desc-color40);
}
.dangerBtnStyle {
  background-color: var(--error-color);
  color: var(--white);
}
.checklistItemsWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.checklistItemWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}
.checklistItemTitleUlStyle {
  /* list-style-position: inside; */
  margin-inline-start: 20px;
}
.checklistItemTitleStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--text-color);
}
.checklistItemIconsWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.checklistItemIconStyle {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.checklistItemEditModalStyle {
  top: -16px !important;
  width: 320px;
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
}
.checklistItemsCreateWrapperStyle {
  display: flex;
  align-items: center;
  gap: 6px;
}
.checklistItemCreateInputStyle {
  padding: 8px 12px;
  height: auto;
}

.datePickerIconStyle {
  width: 10px;
}
.inputWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.freqInputStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 12px;
  border-radius: 4px;
  gap: 8px;
  background-color: var(--bg-primary-color);
  cursor: pointer;
}
.freqInputValueStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--text-color);
}
.selectDropIconWrapperStyle {
  width: 10px;
}
.selectDropIconWrapperStyle img {
  transition: all 0.3s ease-in-out;
}
.selectDropIconRotateStyle {
  transition: all 0.3s ease-in-out;
  transform: rotate(180deg);
}
.freqModalStyle {
  width: 320px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  padding: 8px 0px;
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
}
.freqOptionStyle {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
}
.freqOptionStyle:last-child {
  border-bottom: none;
}
.activeFreqOptionStyle,
.freqOptionStyle:hover {
  background-color: var(--bg-primary-color);
}
.freqOptionTitleStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-color);
}
.freqOptionDescStyle {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: var(--desc-color60);
}
.recurrenceModalStyle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
}
.customOverlayStyle {
  z-index: 1;
}
.recurrenceTitleStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--text-color);
}
.recurrenceBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.recurrenceBlockLabelStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--desc-color60);
}
.recurrenceSubBlockStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.numberInputWrapperStyle {
  width: fit-content;
}
.numberInputStyle {
  width: 100px;
  height: 36px;
  padding: 8px 12px;
  background-color: var(--bg-secondary-color);
  border: none;
  outline: none;
  border-radius: 4px;
}
.numberInputStyle::-webkit-inner-spin-button,
.numberInputStyle::-webkit-outer-spin-button {
  opacity: 1;
}
.recurrenceTypeWrapperStyle {
  width: auto;
  height: 36px;
  padding: 8px 12px;
  background-color: var(--bg-secondary-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.recurrenceTypeTextStyle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 450;
  color: var(--text-color);
}
.recurrenceTypeModalStyle {
  width: 120px;
  border: 1px solid var(--border-color);
  padding: 4px 0px;
  border-radius: 4px;
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
}
.recurrenceTypeItemStyle {
  padding: 4px 12px;
  cursor: pointer;
}
.recurrenceTypeItemStyle:hover,
.recurrenceTypeActiveItemStyle {
  background-color: var(--bg-primary-color);
}
.recurrenceTypeItemTextStyle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--text-color);
}
.recurrenceWeekDaysWrapperStyle {
  display: flex;
  align-items: center;
  gap: 3px;
}
.recurrenceDayTextStyle {
  flex-shrink: 0;
  width: 36px;
  height: 24px;
  border-radius: 16px;
  background-color: var(--bg-primary-color);
  display: grid;
  place-items: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--text-color);
  cursor: pointer;
}
.recurrenceDayActiveTextStyle {
  color: var(--white);
  background-color: var(--primary-color);
}
.recurrenceEndsBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.recurrenceEndsItemStyle {
  height: 36px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.checkBoxStyle {
  width: 16px;
  height: 16px;
}
.checkBoxTitleStyle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 450;
  color: var(--text-color);
}
.disableTextStyle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 450;
  color: var(--desc-color40);
}
.datePickerContainerStyle {
  min-width: 100px;
  border-color: transparent;
  background-color: var(--bg-secondary-color);
}
.recurrenceBtnWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}
.footerBlockStyle {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}
.btnViewStyle {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
}
.footerLengthTextStyle {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--desc-color60);
}
.footerLengthSpanStyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--text-color);
}
.errorTextStyle {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--error-color);
}
/* save popup styles  */
.savePopupStyle {
  height: 144px;
  top: -20px !important;
  bottom: unset;
  /* right: -5% !important; */
  background-color: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  border: 1px solid var(--border-color);
}
.overlayStyle {
  z-index: 1;
}
.savePopupInsideStyle {
  max-width: 320px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
}
.savePopupBtnsWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}
.templateInputStyle {
  height: 36px;
}
/* choose modal styles */
.chooseModalStyle {
  position: fixed;
  top: 50%;
  right: 20px !important;
  left: auto;
  transform: translateY(-50%);
  max-width: 568px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
}
.chooseModalInnerStyle {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  max-height: 70%;
}
.headerSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
}
.headerTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--text-color);
}
.closeIconStyle {
  width: 20px;
  height: 20px;
}
.templatesContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}
.itemStyle {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 12px;
  background-color: var(--bg-secondary-color);
  border-radius: 4px;
  cursor: pointer;
}
.selectedItemStyle {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 12px;
  background-color: var(--bg-secondary-color);
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}
.itemWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}
.itemHeaderTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.itemContentTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color60);
}
.radioUnSelectContainerStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid var(--dodger-blue-color10);
  position: relative;
}
.radioSelectContainerStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: var(--primary-color);
}
.radioSelectDotStyle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--white);
}
.checklistContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 8px 12px;
}
.checklistItemHeaderContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.checklistItemHeaderTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.checklistListStyle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.chooseFileContainerStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.fileStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: var(--white);
  padding: 8px 12px;
  max-width: 210px;
  border-radius: 4px;
}
.fileDataViewStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
}
.fileTextStyle {
  overflow: hidden;
  color: var(--text-color);
  text-overflow: ellipsis;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.fileDescStyle {
  color: rgba(30, 30, 32, 0.4);
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.choosePopupBtnWrapperStyle {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding: 0px 20px 16px 20px;
}
@media screen and (max-width: 480px) {
  .freqModalStyle {
    max-height: calc(40vh);
    overflow: scroll;
  }
  .freqModalStyle {
    scrollbar-width: auto;
  }
  .freqModalStyle::-webkit-scrollbar {
    display: block;
    width: 4px;
    height: 4px;
  }
  .freqModalStyle::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    cursor: pointer;
    border-radius: 4px;
  }
  .savePopupStyle {
    position: fixed !important;
    bottom: 0 !important;
    top: unset !important;
    width: 100%;
    transform: none !important;
    border-radius: 8px 8px 0px 0px;
  }
  .savePopupInsideStyle {
    max-width: 100%;
    width: 100%;
  }
  .chooseModalStyle {
    position: fixed !important;
    bottom: 0 !important;
    top: auto !important;
    right: unset !important;
    width: 100%;
    transform: none !important;
    border-radius: 8px 8px 0px 0px;
    left: unset !important;
  }
}
