.containerStyle {
  width: 100%;
  height: 100%;
  background: var(--white);
  display: flex;
  flex-direction: row;
}
.ticketsSectionWrapperStyle {
  max-width: 360px;
  min-width: 360px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  border-right: 1px solid var(--border-color);
  overflow-y: scroll;
}
.ticketOverViewWrapperStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
}
.headerSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 20px 4px 20px;
  background-color: var(--white);
  position: sticky;
  top: 0;
}
.searchWrapperStyle {
  display: flex;
  gap: 8px;
  background-color: var(--white);
}
.searchInputStyle {
  background-color: var(--light-gray-color);
  height: 36px;
  padding: 8px 12px;
  padding-left: 36px !important;
}
.searchBtnStyle {
  padding: 8px 16px;
  gap: 8px;
  border: 1px solid var(--border-color);
}
.searchIconStyle {
  width: 20px;
  height: 20px;
}
.filterSectionWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.headerTextStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
/* filter wrapper stylees */
.selectBtnStyle {
  padding: 4px 8px;
  box-shadow: none;
  border-radius: 4px;
  background-color: var(--bg-secondary-color);
}
.selectPopUpStyle {
  background: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 8px;
  top: 0px !important;
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
}
.optionsWrapperStyle {
  width: 192px;
  display: flex;
  gap: 8px;
  padding: 4px 8px;
  align-items: center;
  background: var(--white);
  cursor: pointer;
}
.filterTextStyle {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.customCheckBoxStyle {
  width: 16px;
  height: 16px;
  min-width: 16px;
  border-radius: 4px;
  border: 1px solid rgba(30, 30, 32, 0.2);
  background: #fff;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
.customCheckBoxStyle:checked {
  background: #236bfe;
  position: relative;
}
.disabledCheckBoxStyle {
  opacity: 0.4;
}
.customCheckBoxStyle:checked::before {
  content: url('assets/images/checkIcon.svg');
  position: absolute;
  left: 2px;
  top: -3px;
  width: 16px;
  height: 16px;
}
/* list wrapper stylees */
.listWrapperStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 8px 4px;
}
.listCustomStyle:hover {
  background-color: var(--bg-secondary-color);
}
.emptyViewStyle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emptyViewTextStyle {
  color: var(--desc-color60);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  width: 60%;
}
.emptySectionViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.emptyDataTextStyle {
  color: var(--desc-color60);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  width: 60%;
}
.backOptionViewStyle {
  display: none;
}
.backArrowStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.backTextStyle {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  color: var(--text-color);
}
@media screen and (max-width: 480px) {
  .ticketsSectionWrapperStyle {
    max-width: 100%;
    max-height: 100vh;
    border-right: none;
  }
  .headerSectionStyle {
    padding: 16px 16px 4px 16px;
  }
  .backOptionViewStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    padding: 20px;
    gap: 8px;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    background-color: var(--white);
    top: 0px;
    z-index: 1;
  }
}
