.containerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.uploadBlockStyle {
  padding: 20px;
  border-radius: 4px;
  background-color: var(--bg-secondary-color);
  border: 1px dashed var(--desc-color20);
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
}
.uploadBlockTextViewStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.titleStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--desc-color80);
}
.descStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--desc-color40);
}
.fileListStyle {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}
.listItemStyle {
  width: 262px;
  display: flex;
  padding: 8px 12px;
  border-radius: 4px;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-primary-color);
}
.listItemViewStyle {
  overflow: hidden;
}
.listItemTitleStyle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
  word-wrap: break-word;
}
.listItemDescStyle {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--desc-color40);
}
.closeIconViewStyle {
  min-width: 20px;
  min-height: 20px;
}
