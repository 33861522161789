.containerStyle {
  width: 100%;
  max-height: calc(100vh - var(--header-height) * 2);
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  position: relative;
}
.headerBlockStyle {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background-color: var(--bg-secondary-color);
}
.headerLeftSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.headerTitleWrapperStyle {
  display: flex;
  gap: 2px;
  align-items: center;
}
.headingStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}
.iconLgStyle {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  position: relative;
}
.iconStyle {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.descStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.dotRedStyle {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: var(--error-color);
  border: 1px solid var(--white);
}
.dotGreenStyle {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--dark-green-color);
  border: 1px solid var(--white);
}
.dotYellowStyle {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--amber-orange-color);
}
.dotMixedColorContainerStyle {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid var(--white);
}
.dotMixedRedStyle {
  width: 8px;
  height: 4px;
  flex-shrink: 0;
  background-color: var(--error-color);
}
.dotMixedYellowStyle {
  width: 8px;
  height: 4px;
  flex-shrink: 0;
  background: var(--amber-orange-color);
}
.dotMixedGreenStyle {
  width: 8px;
  height: 4px;
  flex-shrink: 0;
  background: var(--dark-green-color);
}
.contentBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 20px;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.blockTitleWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  cursor: pointer;
}
.titleStyle {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.subTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.rotateIconStyle {
  transform: rotate(180deg);
}
/* un hold asset start */
.unholdBlockStyle {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  background-color: var(--bg-secondary-color);
}
.unholdContentWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.unholdTitleStyle {
  font-family: 'InterTight', sans-serif;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.unholdDescStyle {
  color: var(--desc-color60);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.unholdLinkStyle {
  color: var(--primary-color);
}
/* un hold asset end */
/* complaints start */
.complaintsBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.complaintsListStyle {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.complaintsListItemStyle {
  display: flex;
  gap: 8px;
  width: 192px;
  flex-shrink: 0;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: var(--lavender-color);
}
.complaintsListItemContentStyle {
  display: block;
  width: calc(100% - 28px);
}
.complaintsListItemTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.complaintsListItemSubTitleStyle {
  color: var(--desc-color80);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.complaintsListItemDescStyle {
  color: var(--desc-color40);
  font-weight: 450;
}
/* complaints end */
/* checklist start */
.checklistBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.checklistListStyle {
  background: var(--bg-secondary-color);
  border-radius: 8px;
}
.checklistItemStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
}
.checklistItemStyle:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
.checklistItemRightSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}
.checklistActionWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checklistActionBtnWrapperStyle {
  display: flex;
  gap: 2px;
  align-items: center;
}
.checklistTimeStampStyle {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--desc-color40);
}
.btnSmStyle {
  padding: 0px 4px;
  border-radius: 4px;
  color: var(--desc-color90);
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  height: 20px;
  gap: 2px;
  box-shadow: none;
}
.btnStyle {
  color: var(--text-color);
  padding: 8px;
  border-radius: 8px;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: var(--bg-secondary-color);
  border: 1px solid var(--light-shade-gray-color);
  box-shadow: none;
}
.lightBtnStyle {
  background-color: var(--white);
  border: 1px solid var(--light-shade-gray-color);
}
.lightGreenBtnStyle {
  background-color: var(--mint-green-color);
  border: 1px solid var(--border-pale-aqua-hue-color);
}
.lightRedBtnStyle {
  background-color: var(--light-pink);
  border: 1px solid var(--border-light-pink-shade);
}
.lightYellowBtnStyle {
  background-color: var(--cosmic-latte-color);
  border: 1px solid var(--border-pale-yellow-color);
}
.disabledBtnStyle {
  opacity: 0.8;
  pointer-events: none;
}
.disabledPointerBtnStyle {
  cursor: default;
}
.folatRightStyle {
  margin-left: auto;
}
/* checklist end */
/* zero checklist start */
.zeroChecklistWrapperStyle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.zeroChecklistBtnStyle {
  padding: 8px 12px;
  border-radius: 8px;
}
/* zero checklist end */
/* attachements start */
.attachementsBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.attachmentsListStyle {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}
.attachmentsListItemStyle {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  max-width: 192px;
  width: 100%;
  flex-shrink: 0;
  padding: 8px 20px 8px 12px;
  position: relative;
  background-color: var(--bg-primary-color);
  cursor: pointer;
}
.attachmentListItemTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.attachmentListItemDescStyle {
  color: var(--desc-color40);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.attachmentTailStyle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background: linear-gradient(
      135deg,
      rgba(205, 213, 223, 1) 0%,
      rgba(205, 213, 223, 1) 50%,
      transparent 0%
    ),
    linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, white 0%);
}
/* attachments start */
/* description start */
.descriptionBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.descriptionWrapperStyle {
  display: block;
  border-radius: 4px;
  padding: 12px;
  background-color: var(--bg-secondary-color);
}
/* description end */
/* comments start */
.commentsBlcokStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.commentsContentSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--bg-secondary-color);
}
.commentsListStyle {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 24px;
  gap: 16px;
}
.commentsListItemStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.commentsListItemProfileWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
}
.commentAvatarStyle {
  width: 25px;
  height: 25px;
  font-size: 12px;
  font-weight: 600;
}
.commentProfileTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.commentTimeStampStyle {
  color: var(--desc-color40);
  font-size: 12px;
  font-weight: 600;
}
.replyListWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.replyWrapperStyle {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  width: fit-content;
}
.replyLinkStyle {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.rotateIcon180Style {
  transform: rotate(180deg);
}
.replyItemStyle {
  display: flex;
  gap: 8px;
}
.replyCurveStyle {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-style: solid;
  border-color: var(--border-color);
  border-width: 0px 0px 1px 1px;
  border-bottom-left-radius: 8px;
}
.dividerStyle {
  width: 100%;
  border: 1px solid var(--border-color);
}
.moreCommentsLinkStyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  cursor: pointer;
}
/* comments end */
/* report issue modal start */
.reportModalStyle {
  position: absolute;
  top: var(--header-height);
  left: unset;
  right: 2%;
  max-width: 568px;
  width: 100%;
  max-height: calc(100% - 2 * var(--header-height));
  height: auto;
  overflow: hidden;
}
.reportModalOverlayStyle {
  z-index: 1;
}
.reportIssueContainerStyle {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  position: relative;
}
.reportIssueHeaderBlockStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}
.reportIssueHeaderContentStyle {
  display: flex;
  flex-direction: column;
}
.reportIssueHeaderTitleStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-color);
}
.reportIssueBodyBlcokStyle {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}
.reportIssueDataBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 5 * var(--header-height));
  overflow-y: scroll;
}
.reportIssueBottomViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.reportIssueBtnBlockStyle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.errorTextStyle {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--error-color);
}

@media screen and (max-width: 480px) {
  .reportModalStyle {
    bottom: 0 !important;
    height: auto;
    left: 0 !important;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0 !important;
    width: 100%;
  }
}
