.headerWrapperStyle {
  display: flex;
  width: 100%;
  padding: 20px;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  background: var(--white);
  backdrop-filter: blur(40px);
}
.headerLeftSectionStyle {
  display: flex;
  gap: 8px;
  align-items: center;
}
.headerTitleWrapperStyle {
  display: flex;
  gap: 4px;
  align-items: center;
}
.headerTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.inActiveTitleStyle {
  color: var(--desc-color60);
}
.cursorStyle {
  cursor: pointer;
}
.headerRightSectionStyle {
  display: flex;
  align-items: center;
  gap: 14px;
}
.headerIconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.profileIconStyle {
  border-radius: 50%;
}
.profilePopupStyle {
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  top: 5px !important;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.notificationsModalViewStyle {
  max-width: 568px;
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  top: 5px !important;
  display: flex;
  flex-direction: column;
}
.profileWrapperStyle {
  display: flex;
  align-items: center;
  gap: 16px;
}
.switchAccWrapperStyle {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  justify-content: space-between;
  padding: 6px 10px;
  border-radius: 8px;
  gap: 10px;
}

.accountWrapperStyle {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.accTextStyle {
  margin: 0;
}
.downArrowImgStyle {
  width: 12px;
  height: 9px;
}
.companyListWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-top: 1px solid var(--border-color);
  padding-top: 4px;
  overflow: scroll;
  max-height: calc(100vh - 300px);
}
.companyListWrapperStyle {
  scrollbar-width: auto;
}
.companyListWrapperStyle::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}
.companyListWrapperStyle::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  cursor: pointer;
  border-radius: 4px;
}

.accDataWrapperStyle {
  padding: 4px 4px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.selectedAccDataWrapperStyle {
  background-color: #f4f4f4;
}
.accDataWrapperStyle:hover {
  background-color: var(--border-color);
  transition: 0.3s all ease-in-out;
}
.logOutBlockStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
}
.logOutImgStyle {
  width: 20px;
  height: 20px;
}
.logOutTextStyle {
  color: var(--error-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.searchModalViewStyle {
  position: fixed !important;
  inset: 60px auto auto 50% !important;
  transform: translate(-50%) !important;
}
.remainderOverModalOverlayStyle {
  z-index: 1;
}
.notificationsViewStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
}
@media screen and (max-width: 480px) {
  .searchModalViewStyle {
    width: 100%;
    /* bottom: 0px !important; */
    /* top: unset !important; */
  }
  .notificationsModalViewStyle {
    width: 100%;
    height: auto !important;
    border-radius: 8px 8px 0 0;
    display: flex;
    position: fixed;
    bottom: 0 !important;
    right: auto !important;
    z-index: 2;
  }
  .headerIconStyle {
    width: 24px;
    height: 24px;
  }
}
