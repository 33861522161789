.containerStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
}
.subTitleContainerStyle {
  max-width: 180px;
  overflow: hidden;
}
.subTitleLabelStyle {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectedCardStyle {
  background-color: var(--bg-secondary-color);
}
.iconWrapperStyle {
  min-width: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
}
.iconStyle {
  width: 12px;
  height: 12px;
}
.unresolvedBgViewStyles {
  background: var(--primary-color);
}
.inProgressBgViewStyles {
  background: var(--green-color);
}
.inReviewBgViewStyles {
  background: var(--orange-color);
}
.inMarkAsLostBgViewStyles {
  background: var(--magenta-rose-color);
}
.inClosedBgViewStyles {
  background: var(--medium-gray-color);
}
.assigenedBgViewStyles {
  background: var(--primary-color);
}
.cannotSolveBgViewStyles {
  background: var(--error-color);
}
.detailsWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.titleTextStyle {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.descTextStyle {
  width: 100%;
  color: var(--desc-color60);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
.detailsSubWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.btnViewStyle {
  cursor: auto;
  padding: 4px 8px;
  border-radius: 4px;
  text-wrap: nowrap;
  position: unset;
}
.unresolvedTextStyles {
  color: var(--primary-color);
}
.inProgressTextStyles {
  color: var(--green-color);
}
.inReviewTextStyles {
  color: var(--orange-color);
}
.inMarkAsLostTextStyles {
  color: var(--magenta-rose-color);
}
.inClosedTextStyles {
  color: var(--medium-gray-color);
}
.assigenedTextStyles {
  color: var(--primary-color);
}
.cannotSolveTextStyles {
  color: var(--error-color);
}
