.containerStyle {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 120px);
  height: 100%;
  overflow: hidden;
}
.headerViewStyle {
  width: 100%;
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}
.headerTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-color);
}
.colseIconStyle {
  max-width: 20px;
  width: 100%;
  max-height: 20px;
  height: 100%;
  cursor: pointer;
}
.activeViewStyle {
  background-color: var(--bg-primary-color);
}
.remainderOverModalOverlayStyle {
  z-index: 1;
}
.remainderOverModalStyle {
  width: 480px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
  z-index: 10;
}
.notificationsViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  height: 100%;
  overflow: scroll;
}
.notificationsViewStyle {
  scrollbar-width: auto;
}
.notificationsViewStyle::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}
.notificationsViewStyle::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  cursor: pointer;
  border-radius: 4px;
}
.notificationsSubViewStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-bottom: 1px solid var(--border-color);
}
.notificationsSubViewStyle:hover {
  background-color: var(--bg-primary-color);
  border-radius: 4px;
}
.notificationStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.commentAvatarStyle {
  min-width: 35px;
  max-height: 35px;
  font-size: 12px;
  font-weight: 600;
}
.notificationTitleAndDesc {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.notificationTitleStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}
.notificationDescStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--desc-color60);
}
.markViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--primary-color);
}
.readedMsgViewStyle {
  opacity: 0.6;
}
.emptyDataViewStyle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.emptyDataTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--desc-color60);
}
@media screen and (max-width: 480px) {
  .containerStyle {
    width: 100%;
    max-height: 95%;
    height: 100%;
  }
  .remainderOverModalStyle {
    position: fixed !important;
    width: 100%;
    top: unset !important;
    left: unset !important;
    bottom: 0px !important;
    height: fit-content;
    transform: none !important;
    border-radius: 8px 8px 0px 0px;
  }
  .remainderOverModalOverlayStyle {
    z-index: 2;
  }
}
