.modalContainerStyle {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 120px);
  height: 100%;
  overflow: hidden;
}
.headerContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}
.headerTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--text-color);
}
.closeIconStyle {
  width: 20px;
  height: 20px;
}
.formStyles {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}
.inputsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  overflow: scroll;
}
.reminderTypeWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.capsulesWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.labelTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: var(--desc-color60);
}
.capsuleStyle {
  border-radius: 4px;
  background-color: var(--light-mint-green-color);
}
.capsuleUnselectStyle {
  border-radius: 4px;
  font-weight: 500;
}
.capsuleLabelStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color40);
}
.inputsSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.datePickerWrapperStyle {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 16px;
}
.datePickerStyle {
  max-width: 325px;
  width: 100%;
  height: 44px;
  padding: 12px;
}

.timeInputsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.timeSectionWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.timeInputStyle {
  max-width: 90px;
  height: 44px;
  display: flex;
  flex-direction: row;
  border: 1px solid var(--border-color);
  padding: 12px;
  border-radius: 4px;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}
.chevronIconStyle {
  width: 10px;
  height: auto;
}
.timeTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color40);
}
.timeSelectTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.popUpWrapperStyle {
  max-width: 60px;
  width: 100%;
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 0;
}
.timerPopUpWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popUpWrapperStyle {
  scrollbar-width: auto;
}
.popUpWrapperStyle::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}
.popUpWrapperStyle::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  cursor: pointer;
  border-radius: 4px;
}

.selectTimeTextStyle {
  width: 100%;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: center;
  color: var(--text-color);
  padding: 4px;
  cursor: pointer;
}
.selectTimeTextStyle:hover {
  text-align: center;
  width: 100%;
  padding: 4px;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--text-color);
  background-color: var(--bg-primary-color);
  cursor: pointer;
}
.repeatNumberInputSTyle {
  max-width: 90px;
  width: 100%;
}
.endTimerContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.infoTextWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}
.infoTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  color: var(--desc-color40);
}
.repeatPopUpWrapperStyle {
  max-width: 128px;
  width: 100%;
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 0;
}
.repeatPopUpWrapperStyle {
  scrollbar-width: auto;
}
.repeatPopUpWrapperStyle::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}
.repeatPopUpWrapperStyle::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  cursor: pointer;
  border-radius: 4px;
}
.btnSectionStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 20px;
}
.errorTextStyle {
  font-family: 'Manrope', sans-serif;
  color: var(--error-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.activeOptionStyle {
  background-color: var(--light-mint-green-color);
}
.checkBoxStyle {
  width: 16px;
  height: 16px;
}
.checkBoxTitleStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--text-color);
}
.attachmentsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.attachmentsLabelViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.labelIconStyle {
  width: 16px;
  height: 16px;
}
@media screen and (max-width: 480px) {
  .modalContainerStyle {
    width: 100%;
    max-height: 100%;
  }
  .datePickerWrapperStyle {
    display: grid;
    grid-template-columns: auto;
    gap: 16px;
  }
}
