/* // table view Style  */
.tableSectionStyle {
  width: 100%;
  height: 100%;
}
.ticketsTableWrapperStyle {
  padding: 0;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  border-collapse: collapse;
  vertical-align: middle;
  width: 100%;
  /* overflow-x: scroll; */
}
.tableHeaderRowStyle th {
  padding: 0px 16px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(30, 30, 32, 0.4);
  background: #ffffff;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  text-align: left;
  white-space: nowrap;
}
.tableHeaderRowStyle th:last-child {
  border-right: none;
}
.tableHeaderRowStyle th:first-child,
.tableRowStyle td:first-child {
  padding-left: 0px;
}
.tableRowStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
}
.tableRowStyle td {
  padding: 10px 16px;
}
.clusInfoViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.titleCustomColStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.dotViewStyle {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: var(--error-color);
}
.ticketStatusWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.clusterTitleTextStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color);
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
.clusterticketsTextStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--error-color);
}
.clusterDescTextStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
  color: var(--desc-color60);
}
.tableSmallTextStyle {
  font-weight: 450;
  font-size: 13px;
  line-height: 18px;
  color: var(--desc-color60);
  white-space: nowrap;
}
.clusterTicketsWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.emptyDataStyle {
  color: var(--desc-color60);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 16px;
  padding: 10px;
}
.clusterListItemIconStyle {
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 24px;
  flex-shrink: 0;
}
.dailyBgStyle {
  background-color: var(--orange-color);
}
.weeklyBgStyle {
  background-color: var(--deep-purple-color);
}
.monthlyBgStyle {
  background-color: var(--dark-green-color);
}
.customBgStyle {
  background-color: var(--magenta-color);
}
.emptyDataViewStyle {
  width: 100%;
  height: calc(100vh - 300px);
  background-color: var(--bg-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  /* border-style: dotted; */
}
.emptyDataSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.emptyDataTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}
.avatarImgStyle {
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 480px) {
  .tableSectionStyle {
    width: 100%;
    height: 100%;
    flex-direction: row;
    display: flex;
    overflow-x: scroll;
  }
}
