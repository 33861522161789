.inputWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}
.inputLabelStyle {
  color: var(--desc-color60);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}
.inputStyle {
  width: 100%;
  height: 44px;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px;
  color: var(--text-color);
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
}
.textAreaStyle {
  height: auto;
  resize: none;
}
.inputStyle::placeholder {
  color: var(--steel-gray-color);
}
.inputStyle:active,
.inputStyle:focus {
  outline: none;
}
.inputErrorStyle {
  color: var(--error-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.leftIconStyle {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
}
.rightIconStyle {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(50%, -50%);
}
.inputWrapperStyle:has(.leftIconStyle) .inputStyle {
  padding-left: 40px;
}
.inputWrapperStyle:has(.rightIconStyle) .inputStyle {
  padding-right: 40px;
}
