.detailsContainerStyle {
  width: 100%;
  /* height: 100%; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
.loaderViewStyle {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
}
.assetInfoSectionStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.assetInfoViewStyle,
.assetStatusViewStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}
.assetWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.avatarViewStyle {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  padding: 16px 8px;
  background-color: var(--bg-secondary-color);
}
.avatarViewStyle img {
  object-fit: contain;
}
.assetDetailsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.titleTextStyles {
  color: var(--text-color);
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.descTextStyle {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  color: var(--desc-color80);
}
.optionsBtnStyle {
  padding: 4px;
  width: 28px;
  height: 28px;
  border-radius: 4px;
}
.noteViewStyle {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: var(--light-pink);
  padding: 8px 16px;
  border-radius: 4px;
}
.noteWhenLostAssetStyle {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: var(--pale-pink-shade-color);
  padding: 8px 16px;
  border-radius: 4px;
}
.infoIconStyle {
  width: 20px;
  height: 20px;
}
.noteTextStyle {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
/* // asset Assign UnAssign Style  */
.assetAssignUnAssignWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.assetAssignWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.tabsWrapperStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}
.tabBtnStyle {
  background-color: var(--bg-primary-color);
  color: var(--text-color);
  padding: 8px;
}
.capsuleViewStyle {
  border-radius: 4px;
  padding: 8px;
  background-color: var(--white);
}
.selectedCapsuleStyle {
  background-color: var(--bg-primary-color);
  border: none;
  border-radius: 4px;
  padding: 8px;
}
.capsuleLableStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--desc-color40);
}
.selectedLableStyle {
  color: var(--text-color);
}
/* tab content view style  */
.tabContentViewStyle {
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
}
/* tab content view style end  */
/* overView content view style  */
.overViewStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.userInfoSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}
.userInfoWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.userInfoSubWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.labelWrapperStyle {
  width: 568px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.remaindersViewStyle {
  max-width: 568px;
  width: 100%;
  position: relative;
}
.reminderModalViewStyle {
  position: fixed;
  top: 60px;
  left: unset;
  right: 20px;
  width: 568px;
  border-radius: 4px;
}
.viewAllAndBtnWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.viewAllWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.viewAllTextStyle {
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--deep-purple-color);
}
.viewAllIconStyle {
  width: 16px;
  height: 16px;
}
.lableTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--text-color);
}
.statisticsCardWrapperStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.statisticsCardContainerStyle {
  flex-direction: column-reverse;
  background-color: var(--light-mint-green-color);
  padding: 12px;
  min-width: 184px;
  width: fit-content;
  height: auto;
}
.countStatisticsStyle {
  font-size: 16px;
  line-height: 20px;
}
/* overView content view style end  */

/* // table view Style  */
.tableSectionStyle {
  width: 100%;
  height: 100%;
}
.tableWrapperStyle {
  padding: 0;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  border-collapse: collapse;
  vertical-align: middle;
  width: 100%;
  /* overflow-x: scroll; */
}
.tableWrapperStyle td {
  width: 33%;
}
.tableHeaderRowStyle th {
  padding: 0px 16px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(30, 30, 32, 0.4);
  background: #ffffff;
  border-bottom: 1px solid var(--border-color);
  /* border-right: 1px solid var(--border-color); */
  text-align: left;
}
.tableHeaderRowStyle th:last-child {
  border-right: none;
}
.tableHeaderRowStyle th:first-child,
.tableRowStyle td:first-child {
  padding-left: 0px;
}
.tableRowStyle {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
}
.tableRowStyle td {
  padding: 10px 16px;
}
.customColStyle {
  display: flex;
  flex-direction: column;
}
.avatarWrapperStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.avatarStyle {
  width: 24px;
  height: 24px;
  font-size: 14px;
}
.tableSmallTextStyle {
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  color: var(--desc-color60);
}
.assignedStyle {
  color: var(--primary-color);
}
.lostStyle {
  color: var(--error-color);
}
.unAssignedStyle {
  color: var(--desc-color60);
}
.emptyDataStyle {
  color: var(--desc-color60);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 16px;
  padding: 10px;
}
.actionBtnViewStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.deleteIconStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.btnStyle {
  position: relative;
}
.iconStyle {
  width: 20px;
  height: 20px;
}
.actionOptionsViewStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 176px;
}
.actionBtnStyle {
  background-color: var(--blue-ribbon-color);
  color: var(--primary-color);
}
.optionsViewStyles {
  padding: 4px;
  width: 100%;
  border-radius: 4px;
  background: var(--lightGrayishBlue);
  color: var(--textDark);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  cursor: pointer;
}
.optionsViewStyles:hover {
  background-color: var(--bg-primary-color);
}
.deleteOptionViewStyle {
  color: var(--error-color);
}
.actionPopUpViewStyle {
  top: -5% !important;
  bottom: unset;
  padding: 8px;
  background-color: var(--white);
  box-shadow: 0px 48px 96px 0px var(--shadow-color16);
  border: 1px solid var(--border-color);
}
.alertModalViewStyle {
  width: 470px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.assetModalViewStyle {
  width: 470px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
}
.unAssignedSectionStyle {
  width: 100%;
  height: calc(100vh - 208px);
  display: flex;
}
.unAssignedSubSectionStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-secondary-color);
  border-radius: 8px;
  gap: 16px;
}
.unAssignedSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.msgTextStyle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}
.infoImgStyle {
  width: 24px;
  height: 24px;
}
/* // mobile view styles  */
.currentlyAssignedMobileSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.lableViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.cardBottomSectionStyle {
  align-items: center !important;
}
.cardBottomSectionStyle2 {
  align-items: flex-end !important;
}
.dropDownIconStyle {
  width: 10px;
  height: 12px;
  transition: all 0.5s ease-in-out;
}
.arrowRotateViewStyle {
  width: 10px;
  height: 12px;
  transform: rotate(180deg);
  transition: all 0.5s ease-in-out;
}
.backOptionViewStyle {
  display: none;
}
.backArrowStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.backTextStyle {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  color: var(--text-color);
}
.backOptionSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.emptyDataViewStyle {
  width: 100%;
  height: calc(100vh - 330px);
  background-color: var(--bg-primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  gap: 8px;
  padding: 16px;
}
.emptyassetViewStyle {
  width: 100%;
  height: calc(100vh - 100px);
  background-color: var(--bg-primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  /* border-style: dotted; */
  gap: 8px;
}
.emptyDataSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.emptyDataTextStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
}

/* edit info modal style  */
.modalViewStyle {
  position: fixed;
  top: 60px;
  left: unset;
  right: 20px;
  width: 720px;
  border-radius: 4px;
}
.editAttachmentsModalViewStyle {
  position: fixed;
  top: 60px;
  left: unset;
  right: 20px;
  width: 568px;
  border-radius: 4px;
}
.editAttachmentsViewStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.editAttachmentsHeaderStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}
.attachmentsHeaderTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-color);
}
.atttachmentsContentStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 20px;
}
.attachmentsLableStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.labelTextStyle {
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--desc-color60);
}
.infoIconStyle {
  width: 16px;
  height: 16px;
}
.attachmentsBtnStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.allRemaindersModalViewStyle {
  position: fixed;
  top: var(--header-height);
  left: unset;
  right: 20px;
  width: 568px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
  border: 1px solid var(--border-color);
}
.editInfoBtnStyle,
.reminderBtnStyle {
  display: flex;
  gap: 4px;
  align-items: center;
}
.editInfoTextStyle,
.addNewTextStyle {
  display: none;
}
.btnLeftIconStyle {
  width: 20px;
  height: 20px;
}
.addNewBtnViewStyle {
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  align-self: center;
}
.disableViewStyle {
  pointer-events: none;
  opacity: 0.5;
}
/* delete asset modal styles  */
.alertDeleteModalViewStyle {
  width: 408px;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 48px 96px 0px var(--shadow-color08);
  z-index: 2;
}
.deleteModalContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.deleteModalTextWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.deleteTextStyle {
  font-family: 'InterTight', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--text-color);
}
.deleteModalDescStyle {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  text-align: left;
  color: var(--desc-color80);
}
.deleteModalDescStyle span {
  font-weight: 500;
  color: var(--primary-color);
  cursor: pointer;
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.yesBtnStyle {
  background-color: var(--error-color);
}
.errorTextStyle {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--error-color);
}
.assetDeletedAlertTitleStyle {
  color: var(--error-color);
}
@media screen and (max-width: 480px) {
  .detailsContainerStyle {
    padding: 0px;
    gap: 20px;
  }
  .assetInfoSectionStyle {
    padding: 0px 20px;
  }
  .assetAssignUnAssignWrapperStyle {
    padding: 0px 20px;
  }
  .assetDetailsViewStyle {
    gap: 0px;
  }
  .avatarViewStyle {
    width: 44px;
    height: 44px;
    font-size: 20px;
    padding: 8px;
  }
  .titleTextStyles {
    font-size: 16px;
  }
  .unAssignedSectionStyle {
    padding: 0px 20px;
    height: auto;
  }
  .unAssignedSubSectionStyle {
    padding: 16px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .assetModalViewStyle {
    display: flex;
    height: 100%;
    width: 100%;
  }
  .alertModalViewStyle {
    width: 90%;
  }
  .backOptionViewStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 20px;
    gap: 8px;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    top: 0px;
    width: 100%;
    background-color: var(--white);
  }
  .remaindersViewStyle {
    max-width: 100%;
    width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;
  }
  .reminderModalViewStyle {
    width: 100%;
    height: auto !important;
    border-radius: 8px 8px 0 0;
    display: flex;
    position: fixed;
    bottom: 0 !important;
    right: auto !important;
    /* top: auto !important; */
  }
  .userInfoWrapperStyle,
  .labelWrapperStyle {
    width: 100%;
  }
  .editInfoBtnStyle,
  .reminderBtnStyle {
    display: none;
  }
  .editInfoTextStyle {
    font-family: 'Manrope', sans-serif;
    color: var(--primary-color);
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 2px;
    cursor: pointer;
  }
  .addNewTextStyle {
    display: flex;
    font-family: 'Manrope', sans-serif;
    color: var(--deep-purple-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 2px;
    cursor: pointer;
  }
  .modalViewStyle {
    display: flex;
    height: 90vh;
    width: 100%;
    position: fixed;
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    border-radius: 8px 8px 0px 0px;
  }
  .allRemaindersModalViewStyle {
    display: flex;
    height: auto;
    width: 100%;
    position: fixed;
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    border-radius: 8px 8px 0px 0px;
  }
  .editAttachmentsModalViewStyle {
    display: flex;
    height: auto;
    width: 100%;
    position: fixed;
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    border-radius: 8px 8px 0px 0px;
  }
  .addNewBtnViewStyle {
    display: flex;
  }
  .customListItemStyle {
    width: 100%;
  }
}
