/* admin view styles start */
.containerStyle {
  max-width: 141px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--bg-primary-color);
  padding: 4px 8px;
  align-items: center;
}
.titleTextStyle {
  /* max-width: 89px; */
  width: fit-content;
  overflow: hidden;
  color: var(--text-color);
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-overflow: ellipsis;
}
.dividerStyle {
  border-right: 1px solid var(--border-color);
  height: 20px;
}
.iconViewStyle {
  width: 20px;
  height: 20px;
  cursor: pointer;
  align-items: center;
  display: flex;
}
.iconStyle {
  min-width: 100%;
  height: 100%;
  object-fit: contain;
}
/* admin view styles end */

/* Media query start */
@media screen and (max-width: 480px) {
  .containerStyle {
    max-width: max-content;
    padding: 4px 8px;
  }
  .titleTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .closeImage {
    width: 24px;
    height: 24px;
  }
}
/* Media query end */
