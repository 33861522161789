.sideBarWrapperStyle {
  width: 254px;
  flex: 0 0 254px;
  height: 100vh;
  padding: 20px;
  overflow: hidden;
  background-color: var(--white);
  border-right: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.sideBarLogoSectionStyle {
  display: flex;
  justify-content: space-between;
}
.sideBarLogoStyle {
  width: fit-content;
  height: 24px;
  object-fit: cover;
}
.sideBarCloseIconStyle {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.sideBarSearchIconStyle {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}
.sideBarSearchInputStyle {
  padding: 8px 16px;
  border: none;
  height: 40px;
  background-color: var(--bg-secondary-color);
}
.sideBarNavOuterWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 100%;
  overflow-y: scroll;
}
.sideBarNavWrapperStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.sideBarNavLabelStyle {
  padding: 0px 12px;
  align-items: flex-start;
  align-self: stretch;
  color: var(--desc-color40);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.sideBarNavItemStyle {
  display: flex;
  gap: 4px;
  padding: 4px 8px;
  align-items: center;
  cursor: pointer;
}
.selectedNavItemStyle {
  width: 100%;
  border-radius: 4px;
  background: var(--bg-primary-color);
}
.sideBarNavItemLeftIconStyle {
  width: 16px;
  height: 16px;
}
.sideBarNavItemRightIconStyle {
  width: 24px;
  height: 24px;
}
.sideBarNavItemTitleStyle {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}
.sideBarOverlayStyle {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--desc-color20);
  z-index: 1;
}
@media screen and (max-width: 480px) {
  .sideBarWrapperStyle {
    position: absolute;
    width: 277px;
    z-index: 1;
  }
}
